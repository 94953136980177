// import { AnyMessageParams } from 'yup/lib/types';

export const API_HOST = process.env.REACT_APP_SERVER_HOST;
export const Server_Host = process.env.REACT_APP_AWS_SERVER_HOST;

export enum USER_ROLES {
  Fintech = 'fintech',
  Finance = 'finance',
  Operations = 'operations',
  Marketing = 'marketing',
  SuperAdmin = 'admin',
}

export enum ADMIN_ROLES {
  Fintech = 'fintech',
  Finance = 'finance',
  Operations = 'operations',
  Marketing = 'marketing',
}

export enum FIAT_CURR {
  USD = 'USD',
  AED = 'AED',
  EUR = 'EUR',
  GBP = 'GBP',
}

export enum EDIT_USER_BALANCE_FIAT_CURR {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
}

export enum CRYPTO_CURR {
  BTC = 'BTC',
  ETH = 'ETH',
  USDT = 'USDT',
  USDC = 'USDC',
  Matic = 'Matic',
}

export enum LIMINAL_SUPPORTED_CURR {
  BTC = 'btc',
  ETH = 'eth',
  USDT = 'usdet',
}

export const ADMIN_ACTIONS = {
  FA: 'Full Access (Superadmin)',
  VFDT: 'View Fiat Deposit Tickets',
  VFWT: 'View Fiat Withdraw Tickets',
  VCWT: 'View Crypto Withdraw Tickets',
  VCGT: 'View Crypto Buy Tickets',
  VCST: 'View Crypto Sell Tickets',
  VCDT: 'View Crypto Deposit Tickets',
  UCDT: 'Update Crypto Deposit Tickets',
  UFDT: 'Update Fiat Deposit Tickets',
  UFWT: 'Update Fiat Withdraw Tickets',
  UCWT: 'Update Crypto Withdraw Tickets',
  UCGT: 'Update Crypto Buy Tickets',
  UCST: 'Update Crypto Sell Tickets',
  BAA: 'Bank Applications Access(view,approve,reject)',
  WA: 'Wallet Access(View, Enable, Block, see TRM reports)',
  SDA: 'Sales Dashboard Access(Sales or Marketing Personal)',
  GAUD: 'Get All User Details',
};

export const ROUTES = {
  // COMMON ROUTES
  LOGIN: `${API_HOST}/encryptus/user/login`,
  SUBMIT_ENQUIRY: `${API_HOST}/encryptus/enquiryForm/submitEnquiry`,
  REFRESH_TOKEN: `${API_HOST}/encryptus/user/login/refreshToken`,
  LOGOUT: `${API_HOST}/encryptus/user/logout`,
  REGISTER: `${API_HOST}/encryptus/user/register`,
  UPDATE_USER: `${API_HOST}/encryptus/user/update`,
  CHECK_LOCATION: `${API_HOST}/encryptus/user/checkLocation`,
  WHO_AM_I: `${API_HOST}/encryptus/user/whoamI`,
  OTP: `${API_HOST}/encryptus/user/verifyOTP`,
  ADMIN_OTP: `${API_HOST}/encryptus/admin/verifyOTP`,
  SEND_EMAIL_VERIFY: `${API_HOST}/encryptus/user/verifyEmail`,
  SEND_SMS_VERIFY: `${API_HOST}/encryptus/user/verifyPhone`,
  SEND_PHONE_OTP: `${API_HOST}/encryptus/user/sendPhoneOtp`,
  SEND_EMAIL_OTP: `${API_HOST}/encryptus/user/sendEmailOtp`,
  SEND_SMS_OTP: `${API_HOST}/encryptus/user/verifyUserPhoneOTP`,
  VALIDATE_OTP: `${API_HOST}/encryptus/user/validateEmailOtp`,
  VERIFY_EMAIL: `${API_HOST}/encryptus/admin/verifyUserEmail`,
  GET_SECRET_3FA: `${API_HOST}/encryptus/user/send3faSecret`,
  ENABLE_3FA: `${API_HOST}/encryptus/user/verify3faSecret`,
  INITIATE_3FA: `${API_HOST}/encryptus/user/initiate3fa`,
  GUARD_3FA: `${API_HOST}/encryptus/user/guard3faCheck`,
  SET_CHOICE_3FA: `${API_HOST}/encryptus/user/choice3FA`,
  Check_Pass: `${API_HOST}/encryptus/user/guard3fafStep`,
  OTP_check: `${API_HOST}/encryptus/user/initiate3faStep2`,
  OTP_validation: `${API_HOST}/encryptus/user/guard3faCheckOTP`,
  GACode_check: `${API_HOST}/encryptus/user/guard3faCheckGA`,
  Wallet_data: `${API_HOST}/encryptus/user/addWallet/request`,
  Deposite_request: `${API_HOST}/encryptus/user/fiatFlows/new/fiatDeposit`,
  FIAT_WITHDRAW_REQUEST: `${API_HOST}/encryptus/user/fiatFlows/new/fiatWithdraw`,
  CRYPTO_WITHDRAW_REQUEST: `${API_HOST}/encryptus/user/cryptoFlows/new/cryptoWithdraw`,
  GENERATE_WALLET_ADDRESS: `${API_HOST}/encryptus/user/depositAddress`,
  TERMINATE_USER_OLD_SESSION: `${API_HOST}/encryptus/user/terminateOldSession`,
  TERMINATE_ADMIN_OLD_SESSION: `${API_HOST}/encryptus/admin/terminateOldSession`,
  // FORGOT PASSWORD / RESET PASSWORD FLOW
  FORGOT_PASSWORD: `${API_HOST}/encryptus/user/forgetPassword`,
  RESET_PASSWORD_INIT: `${API_HOST}/encryptus/user/resetPassInit`,
  RESET_USER_PASSWORD: `${API_HOST}/encryptus/user/resetUserPassword`,

  // USER ROUTES
  CREATE_BANK_APPLICATION: `${API_HOST}/encryptus/user/bankApplication/request`,
  FETCH_USER_BANK_APPLICATIONS: `${API_HOST}/encryptus/user/bankApplications`,
  DELETE_BANK_ACCOUNT: (bankID: string) =>
    `${API_HOST}/encryptus/user/deleteBank/${bankID}`,
  CREATE_WALLET: `${API_HOST}/encryptus/user/addWallet/request`,
  FETCH_USER_WALLETS: `${API_HOST}/encryptus/user/wallets`,
  DELETE_USER_WALLET: (walletId: string) =>
    `${API_HOST}/encryptus/user/deleteWallet/${walletId}`,
  APPLY_FOR_KYC: `${API_HOST}/encryptus/user/applyForKyc`,
  EDIT_BANK_ACCOUNT: (bankID: string) =>
    `${API_HOST}/encryptus/user/bankApplication/request/reapply/${bankID}`,
  Crypto_Fetch: `${API_HOST}/encryptus/user/cryptoFlows/fetchQuotes`,
  Fiat_Balance: `${API_HOST}/encryptus/user/fiatFlows/fiatBalance`,
  Crypto_Balance: `${API_HOST}/encryptus/user/cryptoFlows/cryptoBalance`,
  Falconx_Place_Order: `${API_HOST}/encryptus/user/cryptoFlows/placeOrder`,
  SHD_Order_Place_Api: `${API_HOST}/encryptus/user/cryptoFlows/shdigital/placeOrder`,
  USER_TRANSACTION: `${API_HOST}/encryptus/user/mytransactions`,
  GENERATE_AT_SSB: `${API_HOST}/encryptus/user/generateATSSB`,
  UPDATE_KYC_LOGS: `${API_HOST}/encryptus/user/updatekycLogs`,
  Terms_Condition: `${API_HOST}/encryptus/user/acceptTerms&Conditions`,
  Falconx_Place_Sell_Order: `${API_HOST}/encryptus/user/cryptoFlows/placeSellOrder`,
  Sell_flow_Order: `${API_HOST}/encryptus/user/cryptoFlows/placeSellOrder`,
  SHD_Sell_flow_Order: `${API_HOST}/encryptus/user/cryptoFlows/shdigital/placeSellOrder`,
  Price_Update_API: `${API_HOST}/encryptus/user/cryptoFlows/fetchQuotesStreamingPayload`,
  REG_SEND_EMAIL_OTP: `${API_HOST}/encryptus/user/register/sendEmailOtp`,
  REG_VALIDATE_OTP: `${API_HOST}/encryptus/user/register/validateEmailOtp`,

  // ADMIN ROUTES
  Admin_data: `${API_HOST}/encryptus/admin/whoami`,
  ADMIN_LOGIN: `${API_HOST}/encryptus/admin/login`,
  token_admin: `${API_HOST}/encryptus/admin/login/refreshToken`,
  Admin_logout: `${API_HOST}/encryptus/admin/logout`,
  USERS: `${API_HOST}/encryptus/admin/usersCollection`,
  GET_USER: (userID: string) => `${API_HOST}/encryptus/admin/getUser/${userID}`,
  L_USERS: `${API_HOST}/encryptus/ladmin/rsigs/usersCollection`,
  FIN_REPORTS: `${API_HOST}/encryptus/admin/fetchallFinanceReports`,
  Admin_Permissions: `${API_HOST}/encryptus/admin/createPermission`,
  ADD_NEW_ADMIN: `${API_HOST}/encryptus/sadmin/rsigs/new/admin`,
  ADMIN_CHANGE_PASSWORD: `${API_HOST}/encryptus/admin/sendResetOTP`,
  ADMIN_VERIFY_OTP: `${API_HOST}/encryptus/admin/resetPassInit`,
  RESET_ADMIN_PASSWORD: `${API_HOST}/encryptus/admin/resetAdminPassword`,
  Permission_collection: `${API_HOST}/encryptus/admin/permissionCollection?filters=sort={createdAt:-1}&page=1`,
  FETCH_ALL_BANK_APPLICATIONS: `${API_HOST}/encryptus/admin/BankCollection`,
  FETCH_USER_BANK_APPLICATIONS_ADMIN: (iban: string) =>
    `${API_HOST}/encryptus/admin/bankApplication/iban/${iban}`,
  S_FETCH_USER_BANK_APPLICATIONS_ADMIN: (id: string) =>
    `${API_HOST}/encryptus/admin/bankApplication/${id}`,
  FETCH_USER_FIAT_BALANCE_ADMIN: (id: string) =>
    `${API_HOST}/encryptus/admin/fiatBalance/${id}`,
  FETCH_USER_CRYPTO_BALANCE_ADMIN: (id: string) =>
    `${API_HOST}/encryptus/admin/cryptoBalance/${id}`,
  L_FETCH_USER_BANK_APPLICATIONS_ADMIN: (id: string) =>
    `${API_HOST}/encryptus/ladmin/rsigs/bankApplication/${id}`,
  UPDATE_BANK_STATUS: (bankID: string) =>
    `${API_HOST}/encryptus/admin/update/bas/${bankID}`,
  VERIFY_KYC: (id: string) => `${API_HOST}/encryptus/admin/kyc/verified/${id}`,
  FETCH_USER_WALLETS_ADMIN: (id: string) =>
    `${API_HOST}/encryptus/admin/walletInfo/${id}`,
  L_FETCH_USER_WALLETS_ADMIN: (id: string) =>
    `${API_HOST}/encryptus/ladmin/rsigs/walletInfo/${id}`,
  UPDATE_WALLET_STATUS: (walletID: string) =>
    `${API_HOST}/encryptus/admin/update/walletStatus/${walletID}`,
  // L_UPDATE_WALLET_STATUS: (walletID: string) =>
  //   `${API_HOST}/encryptus/ladmin/rsigs/walletStatus/${walletID}`,
  FETCH_ADMIN_COINS: `${API_HOST}/encryptus/admin/getCurrencyPair/info`,
  UPDATE_COIN_GLOBAL: (symbol: string) =>
    `${API_HOST}/encryptus/admin/update/AdminSettings/${symbol}`,
  APPROVE_KYC: (kycApplicationId: string) =>
    `${API_HOST}/encryptus/admin/kyc/verified/${kycApplicationId}`,
  REJECT_KYC: (kycApplicationId: string) =>
    `${API_HOST}/encryptus/admin/kyc/rejected/${kycApplicationId}`,
  FETCH_ALL_KYC_APPLICATIONS: `${API_HOST}/encryptus/admin/KycApplicationCollection`,
  L_FETCH_ALL_KYC_APPLICATIONS: `${API_HOST}/encryptus/ladmin/KycApplicationCollection`,
  Processing_Route: (id: string) =>
    `${API_HOST}/encryptus/admin/ticketSeenConsent/${id}`,

  // MObile Topups and Giftcards+++++++++++++++
  MObile_Topups: `${Server_Host}/v1/tickets/admin/topup`,
  Gift_cards: `${Server_Host}/v1/tickets/admin/giftcard`,

  // ? SET MARGINAL & COMISSION PERCENTAGE
  SET_TRANSACTION_FEE: (user_id: string) =>
    `${API_HOST}/encryptus/admin/set/transactionFees/${user_id}`,
  SET_MARGIN_FEE: (user_id: string) =>
    `${API_HOST}/encryptus/admin/set/marginFees/${user_id}`,

  // ? Edit User Balance
  UPDATE_FIAT_BALANCE: (user_id: string) =>
    `${API_HOST}/encryptus/sadmin/rsigs/update/FiatBalance/${user_id}`,
  UPDATE_CRYPTO_BALANCE: (user_id: string) =>
    `${API_HOST}/encryptus/sadmin/rsigs/update/CryptoBalance/${user_id}`,
  L_UPDATE_CRYPTO_BALANCE: (user_id: string) =>
    `${API_HOST}/encryptus/ladmin/rsigs/update/CryptoBalance/${user_id}`,

  CRYPTO_DETAILS: `${API_HOST}/encryptus/sadmin/rsigs/cgtickets`,
  TREZOR_WALLET: `${API_HOST}/encryptus/admin/trezorWallet`,
  UPDATE_TREZOR_WALLET: `${API_HOST}/encryptus/admin/update/trezorWallet`,
  Deposite_Creator: `${API_HOST}/encryptus/admin/create/cryptoDepositTicket`,
  Sell_Crypto: `${API_HOST}/encryptus/sadmin/rsigs/cstickets`,

  WhiteList: `${API_HOST}/encryptus/admin/whitelist/walletAddress`,
  Fiat_Approval: (id: string) => {
    return `${API_HOST}/encryptus/sadmin/rsigs/fdTicket/finalApproval/${id}`;
  },
  Crypto_approval: (id: string) =>
    `${API_HOST}/encryptus/sadmin/rsigs/cgTicket/finalApproval/${id}`,
  Crypto2_approval: (id: string) =>
    `${API_HOST}/encryptus/sadmin/rsigs/cdTicket/finalApproval/${id}`,
  LADMIN_Crypto2_approval: (id: string) =>
    `${API_HOST}/encryptus/ladmin/rsigs/cdTicket/finalApproval/${id}`,
  Ladmin_Approval_second: (id: string) => {
    return `${API_HOST}/lapprove/cdTicket/${id}`;
  },
  // ? REPORTS
  FIAT_REPORT_TICKETS: () => `${API_HOST}/encryptus/sadmin/rsigs/fdtickets`,
  CRYPTO_BUY_REPORT_TICKETS: () =>
    `${API_HOST}/encryptus/sadmin/rsigs/cgtickets`,
  CRYPTO_DEPOSIT_REPORT_TICKETS: () =>
    `${API_HOST}/encryptus/sadmin/rsigs/cdtickets`,
  LADMIN_CRYPTO_DEPOSIT_REPORT_TICKETS: () =>
    `${API_HOST}/encryptus/ladmin/rsigs/cdtickets`,

  FIAT_REPORT_TICKETS_FILTERED: (
    current: string,
    searchField: string,
    searchValue: any,
    filters: any,
  ) =>
    `${API_HOST}/encryptus/sadmin/rsigs/fdtickets?filters={"transc_status":"${current}", "${searchField}":"${searchValue}", "createdAt": "${filters.createdAt}"}&page=1&sort={"createdAt":-1}`,
  CRYPTO_BUY_REPORT_TICKETS_FILTERED: (
    current: string,
    searchField: string,
    searchValue: any,
    filters: any,
  ) =>
    `${API_HOST}/encryptus/sadmin/rsigs/cgtickets?filters={"transc_status":"${current}", "${searchField}":"${searchValue}", "createdAt": "${filters.createdAt}"}&page=1&sort={"createdAt":-1}`,
  CRYPTO_DEPOSIT_REPORT_TICKETS_FILTERED: (
    current: string,
    searchField: string,
    searchValue: any,
    filters: any,
  ) =>
    `${API_HOST}/encryptus/sadmin/rsigs/cdtickets?filters={"transc_status":"${current}", "${searchField}":"${searchValue}", "createdAt": "${filters.createdAt}"}&page=1&sort={"createdAt":-1}`,
  LADMIN_CRYPTO_DEPOSIT_REPORT_TICKETS_FILTERED: (
    current: string,
    searchField: string,
    searchValue: any,
    filters: any,
  ) =>
    `${API_HOST}/encryptus/ladmin/rsigs/cdtickets?filters={"transc_status":"${current}", "${searchField}":"${searchValue}", "createdAt": "${filters.createdAt}"}&page=1&sort={"createdAt":-1}`,
  FIAT_WITHDRAW_TICKETS: () => `${API_HOST}/encryptus/sadmin/rsigs/fwtickets`,

  FIAT_WITHDRAW_TICKETS_FILTERED: (
    current: string,
    searchField: string,
    searchValue: any,
    filters: any,
  ) =>
    `${API_HOST}/encryptus/sadmin/rsigs/fwtickets?filters={"transc_status":"${current}", "${searchField}":"${searchValue}", "createdAt": "${filters.createdAt}"}&page=1&sort={"createdAt":-1}`,
  FIAT_WITHDRAW_APPROVAL: (id: string) => {
    return `${API_HOST}/encryptus/sadmin/rsigs/fwTicket/finalApproval/${id}`;
  },
  CRYPTO_WITHDRAW_TICKETS: () => `${API_HOST}/encryptus/sadmin/rsigs/cwtickets`,

  CRYPTO_WITHDRAW_TICKETS_FILTERED: (
    current: string,
    searchField: string,
    searchValue: any,
    filters: any,
  ) =>
    `${API_HOST}/encryptus/sadmin/rsigs/cwtickets?filters={"transc_status":"${current}", "${searchField}":"${searchValue}", "createdAt": "${filters.createdAt}"}&page=1&sort={"createdAt":-1}`,
  CRYPTO_WITHDRAW_APPROVAL: (id: string) => {
    return `${API_HOST}/encryptus/sadmin/rsigs/cwTicket/finalApproval/${id}`;
  },

  // ADMIN PARTNERS ROUTES+++++++++
  ADMIN_PARTNERS_FIAT_DEPOSIT: `${Server_Host}/tickets/admin/fdeposits`,
  ADMIN_PARTNERS_CRYPTO_DEPOSIT: `${Server_Host}/tickets/admin/cdeposits`,
  ADMIN_PARTNERS_FIAT_Withdrawls: `${Server_Host}/tickets/admin/fwithdrawals`,
  ADMIN_PARTNERS_CRYPTO_WIthdrawls: `${Server_Host}/tickets/admin/cwithdrawals`,
  Admin_Giftcards: `${Server_Host}/tickets/admin/giftcard`,
  Admin_Topups: `${Server_Host}/tickets/admin/topup`,
  Admin_Partners_Approval_FDTickets: (id: string) =>
    `${Server_Host}/tickets/fdTicket/finalApproval/${id}`,
  Admin_Partners_Approval_CDTickets: (id: string) =>
    `${Server_Host}/tickets/cdTicket/finalApproval/${id}`,
  Admin_Partners_Approval_FWTickets: (id: string) =>
    `${Server_Host}/tickets/fwTicket/finalApproval/${id}`,
  Admin_Partners_Approval_CWTickets: (id: string) =>
    `${Server_Host}/tickets/cwTicket/finalApproval/${id}`,

  // Lower Admin withdraw Routes

  CRYPTO_WITHDRAW_CONSENT: (id: string) => {
    return `${API_HOST}/encryptus/ladmin/rsigs/lapprove/cwTicket/${id}`;
  },

  FIAT_WITHDRAW_CONSENT: (id: string) => {
    return `${API_HOST}/encryptus/ladmin/rsigs/lapprove/fwTicket/${id}`;
  },

  FIAT_WITHDRAW_TICKETS_LOWER_ADMIN: () => {
    return `${API_HOST}/encryptus/ladmin/rsigs/fwtickets`;
  },

  CRYPTO_WITHDRAW_TICKETS_LOWER_ADMIN: () => {
    return `${API_HOST}/encryptus/ladmin/rsigs/cwtickets`;
  },

  Wallet_status: (id: string) =>
    `${API_HOST}/encryptus/admin/update/cgticket/mlWalletStatus/${id}`,
  Coins_Price_API: (id: string) =>
    `https://api.coingecko.com/api/v3/simple/price?ids=${id}&vs_currencies=USD`,
  DETAILED_REPORT: () => {
    return `${API_HOST}/encryptus/sadmin/rsigs/report?filters={"transac_status": "approved"}`;
  },
  DETAILED_REPORT_FILTERED: (startDate: any, endDate: any) => {
    return `${API_HOST}/encryptus/sadmin/rsigs/report?filters={"transac_status": "approved" ,"startDate":"${startDate}", "endDate": "${endDate}"}`;
  },
  EXPORT_CRYPTO_VOLUME: () => {
    return `${API_HOST}/encryptus/sadmin/rsigs/report/export/cryptoVolume`;
  },
  EXPORT_CRYPTO_VOLUME_FILTERED: (startDate: any, endDate: any) => {
    return `${API_HOST}/encryptus/sadmin/rsigs/report/export/cryptoVolume?filters={"startDate":"${startDate}", "endDate": "${endDate}"}`;
  },
  All_PERMISSION: `${API_HOST}/encryptus/admin/permissionCollection?filters={"actions_allowed":""}&page=1&sort={"createdAt":-1}`,
  Selected_Permissions: (selection: string) => {
    return `${API_HOST}/encryptus/admin/permissionCollection?filters={"actions_allowed":"${selection}"}&page=1&sort={"createdAt":-1}`;
  },
  // Lower Admin Transaction page Routes
  Lower_Admin_Fiat_approval: (id: string) => {
    return `${API_HOST}/encryptus/ladmin/rsigs/lapprove/fdTicket/${id}`;
  },
  Lower_Admin_Crypto_approval: (id: string) => {
    return `${API_HOST}/encryptus/ladmin/rsigs/lapprove/CGTicket/${id}`;
  },
  LowerAdmin_CRYPTO_BUY_REPORT_TICKETS: () => {
    return `${API_HOST}/encryptus/ladmin/rsigs/cgtickets`;
  },
  LowerAdmin_FIAT_REPORT_TICKETS: () => {
    return `${API_HOST}/encryptus/ladmin/rsigs/fdtickets`;
  },
  LowerAdmin_FIAT_REPORT_TICKETS_FILTERED: (
    current: string,
    searchField: string,
    searchValue: any,
    filters: any,
  ) =>
    `${API_HOST}/encryptus/ladmin/rsigs/fdtickets?filters={"transc_status":"${current}", "${searchField}":"${searchValue}", "createdAt": "${filters.createdAt}"}&page=1&sort={"createdAt":-1}`,
  LowerAdmin_CRYPTO_BUY_REPORT_TICKETS_FILTERED: (
    current: string,
    searchField: string,
    searchValue: any,
    filters: any,
  ) =>
    `${API_HOST}/encryptus/ladmin/rsigs/cgtickets?filters={"transc_status":"${current}", "${searchField}":"${searchValue}", "createdAt": "${filters.createdAt}"}&page=1&sort={"createdAt":-1}`,
  Lower_admin_SEll_crypto: `${API_HOST}/encryptus/ladmin/rsigs/cstickets`,
  SAdmin_SEllTransaction: (id: string) =>
    `${API_HOST}/encryptus/sadmin/rsigs/csTicket/finalApproval/${id}`,
  LAdmin_SEllTransaction: (id: string) =>
    `${API_HOST}/encryptus/ladmin/rsigs/csTicket/finalApproval/${id}`,
  // ?Ends
  DELETE_PERMISSION: (id: string, action: string) =>
    `${API_HOST}/encryptus/admin/deletePermission?permID=${id}&action=${action}`,

  // ? SUB-ADMIN | FORGOT PASSWORD ROUTES
  SUBADMIN_SEND_RESET_OTP: () =>
    `${API_HOST}/encryptus/admin/subAdmin/sendResetOTP`,
  SUBADMIN_VALIDATE_OTP: () =>
    `${API_HOST}/encryptus/admin/subAdmin/resetPassInit`,
  SUBADMIN_RESET_PASSWORD: () =>
    `${API_HOST}/encryptus/admin/subAdmin/resetSubAdminPassHandler`,
  CRYPTO_DEPOSIT_Request: `${API_HOST}/encryptus/user/cryptoFlows/create/cryptoDepositTicket`,

  // ? ADMIN NOTIFICATIONS
  FETCH_ALL_ADMIN_NOTIFICATIONS: `${API_HOST}/encryptus/admin/notifications`,
  DELETE_ADMIN_NOTIFICATIONS: (createdAt: any) =>
    `${API_HOST}/encryptus/admin/notifications/${createdAt}`,
  DELETE_ALL_ADMIN_NOTIFICATIONS: `${API_HOST}/encryptus/admin/notifications`,

  // PARTNERS ROUTES+++++++++++++++++++++
  Partners_Generate_Token: `${Server_Host}/partners/generate/token`,
  Partners_Login: `${Server_Host}/partners/login`,
  Partners_Info: `${Server_Host}/partners/accountInfo`,
  Partners_Registor: `${Server_Host}/partners/onboarding`,
  Partners_Refresh_Token: `${Server_Host}/partners/refresh/token`,
  Partners_Logout: `${Server_Host}/partners/logout`,
  Partners_Mobile_topups_Order: `${Server_Host}/topup/orders`,
  Partners_GiftCards: `${Server_Host}/payout/giftcard/orders`,

  Partners_FIAT_REPORT_TICKETS: `${Server_Host}/tickets/fiat/deposits`,
  Partners_CRYPTO_REPORT_TICKETS: `${Server_Host}/tickets/crypto/deposits`,
  Partners_FIAT_WITHDRAW_TICKETS: `${Server_Host}/tickets/fiat/withdrawals`,
  Partners_CRYPTO_WITHDRAW_TICKETS: `${Server_Host}/tickets/crypto/withdrawals`,

  Partners_BANK_LIST: `${Server_Host}/partners/forensics/fetchAll/whitelisted/BankAccount`,
  Partners_WALLET_LIST: `${Server_Host}/partners/forensics/fetchAll/whitelisted/WalletAddress`,
  Partners_FETCH_ALL_USERS: `${Server_Host}/partners/fetchall/user`,

  Partners_PAYOUT_GIFTCARD: `${Server_Host}/partners/payout/gitftcard/order`,
  Partners_PAYOUT_MOBILE_TOPUP: `${Server_Host}/partners/forensics/fetchAll/whitelisted/WalletAddress`,
};
