import Footer from 'components/common/Footer';
import NavBar from 'components/common/NavBar';
import React, { useEffect } from 'react';
import classes from 'styles/PrivacyPolicy.module.scss';

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <NavBar />
      {/* Hero section++++++++++++++++ */}
      <section className={classes.HeroSection}>
        <div className={classes.textContainer}>
          <h1>
            <span>Privacy</span> Policy
          </h1>
        </div>
      </section>
      <section className={classes.SpecialDiv}>
        <div>
          <p>
            Encryptus (hereinafter referred to as “<b>we or our</b>”) is
            committed to safeguarding personal information. This Privacy Policy
            relates to our use and collection of personal information relating
            to clients of our platforms, including directors, beneficial owners,
            principals, employees or consultants of our clients and/or vendors
            (hereinafter referred to as “<b>you/your</b>”). <br></br>Unless
            otherwise defined herein, the terms used in Privacy Policy shall
            have the same meaning in the User Agreement posted in the Website.
          </p>

          <p>
            <b> 1. Applicability of this Policy</b>
            <br />
            <br />
            This Privacy Policy applies to the Website and the Services. If you
            do not agree with the terms of this Privacy Policy, you should not
            access this Website and use the Services.
          </p>
          <p>
            With respect to our clients, Encryptus is the data controller of
            your personal information processed under this Privacy Policy.
          </p>
          <p>
            This Privacy Policy provides you with detail about how we use such
            Personal Information. We use commercially reasonable physical,
            electronic and procedural measures to protect your Personal
            Information. This Privacy Policy does not apply to any anonymized
            data which cannot be used to identify you.
            <br />
            We will not use your personal information for purposes other than
            those purposes we have disclosed to you in this Privacy Policy
            without your permission.
          </p>
          <p>
            By visiting, accessing or using our Services or the Website, you
            acknowledge and agree that we use your personal information as set
            out in this Privacy Policy, and that Encryptus is the data
            controller of your Personal Information processed under this Privacy
            Policy.
            <br />
            This Privacy Policy is intended to explain our privacy practices and
            covers the following areas:
          </p>

          {/* <br /> */}
          <ul>
            <li>What Personal Information we may collect</li>
            <li>How do we use your Personal Information</li>
            <li>Whom we may disclose or share your Personal Information</li>
            <li>How we protect your personal information</li>
            <li>Your rights and to contact us</li>
            <li>Cookies</li>
            <li>Modification to this Privacy Policy</li>
            <li>Eligibility</li>
            <li>GDPR notice</li>
          </ul>
          <p>
            <b>2. Information We May Collect About You</b>
            <br />
            <br />
            We may collect the following personal information from you when you
            register with us:
          </p>
          <br />
          <ul>
            <li>Contact Information: name, address, email address</li>
            <li>Account information: username and password</li>
            <li>Financial Information: bank and bank account numbers</li>
            <li>
              Identity Verification Information: date of birth, tax ID number,
              images of government issued ID, passport, national ID or driving
              license
            </li>
            <li>Tax identification number</li>
            <li>Address proof: Utility bill details or similar information</li>
            <li>In addition, for institutional customers:</li>
            <ul>
              <li>
                Organizational Information: Certificate of incorporation,
                business registration, certificate of incumbency, business
                licenses
              </li>
              <li>
                Identification Information for beneficial owners, principals,
                directors and executive management (as applicable)
              </li>
            </ul>
          </ul>
          <p>
            We may also automatically collect certain computer, device and
            browsing information when you access our website:
          </p>
          <ul>
            <li>
              Computer or mobile device information, including IP address,
              operating system, browser type
            </li>
            <li>Website usage information</li>
            <li>Location information</li>
          </ul>
          <p>
            We also may collect personal information about you (including your
            beneficial owners, principals and executive management, as
            applicable) from public databases and ID verification partners,
            including:
          </p>
          <ul>
            <li>public employment profile</li>
            <li>criminal history</li>
            <li>credit history</li>
            <li>
              status on any politically exposed person and sanctions lists
            </li>
            <li>address</li>
            <li>other information to help validate your identity</li>
          </ul>
          <p>
            We also collect the following personal information from your usage
            of our products and services:
          </p>
          <ul>
            <li>
              Account Information: information that is generated by your account
              activity, including, but not limited to, purchases and
              redemptions, deposits, withdrawals and account balances. When we
              require certain personal information from you, it is because we
              are required by applicable law to collect this information or it
              is relevant for specified purposes. We may not be able to serve
              you as effectively or offer you all of our services if you elect
              not to provide certain types of information.
            </li>
          </ul>
          <br />
          <h4>3. Uses Made of Your Personal Information</h4>
          <br />
          <p>We may use your personal information in the ways listed below.</p>
          <ul>
            <li>
              <b>
                To provide our services effectively to our clients and conduct
                our business:-{' '}
              </b>
              to administer our services, including to carry out our obligations
              arising from any agreements entered into between our clients and
              us, which may include passing your data to our payment partners
              and third parties such as agents or contractors or to our advisors
              (e.g., legal, financial, business or other advisors). We may also
              use your personal information to develop our products and
              services, to evaluate new products and services and to improve our
              service quality, including by performing statistical analysis and
              reporting on transactions and site usage. In addition, we may use
              your personal information for financial reporting, management
              reporting, audit and record keeping purposes. This could help us
              to enhance security and safety of our Service as well, and to
              prevent any illegal and improper usage of the Services.
            </li>
            <br />
            <li>
              <b>To manage our risk:- </b>We may use your Personal Information
              to managing the safety, security and integrity of you and our
              Services, including (1) protect, investigate, and avoiding
              fraudulent, unauthorized or illegal activities, (2) monitor and
              identify combat spam, malware and/or other security risks, and (3)
              perform internal operation of our Service such as troubleshoot
              bugs and other operational problems.
            </li>
            <br />
            <li>
              <b>To verify your identity :-</b> for the purposes of providing
              facilities, products or services, including conducting screenings
              or due diligence checks as may be required under applicable law,
              regulation, directive or our Terms and Conditions.
            </li>
            <br />
            <li>
              <b>In relation to fraud prevention :- </b>We and other
              organizations may also access and use certain information to
              prevent fraud as may be required by applicable law and regulation
              and best practice at any given time. If false or inaccurate
              information is provided or fraud is identified or suspected,
              details may be passed to law enforcement and fraud prevention
              agencies and may be recorded by us or by them. In addition, we may
              share information with the financial institutions with which we
              partner to process payments you have authorized. We may also share
              information with our partner or other financial institutions. For
              more information on the AML & anti-fraud measures adopted by us,
              please see section 7 of this Privacy Policy.
            </li>
            <br />
            <li>
              <b>In connection with legal or regulatory obligations:-</b> Law
              enforcement, regulators and the court service :- We may share your
              information with law enforcement, regulatory authorities, tax
              authorities, self regulatory organizations (such as those that
              operate virtual currency derivative exchanges) and officials, or
              other third parties when we are compelled to do so by a subpoena,
              court order, or similar legal procedure, or when we believe in
              good faith that the disclosure of personal information is
              necessary to prevent physical harm or financial loss, to report
              suspected illegal activity or to investigate violations of our
              Terms and Conditions or any other applicable policies. We may also
              use your personal information to otherwise comply with all
              applicable laws, regulations, rules, directives and orders.
            </li>
            <br />
            <li>
              <b>In order to communicate with you :- </b>We may use your
              personal information to communicate with you, including providing
              you with updates on changes to products, services and banking
              facilities (whether made available by us or through us) including
              any additions, expansions, suspensions and replacements of or to
              such products, services and banking facilities and their terms and
              conditions.
            </li>
            <br />
            <li>
              <b>In connection with disputes :-</b> We may use your personal
              information to address or investigate any complaints, claims or
              disputes and to enforce obligations owed to us.
            </li>
            <br />
            <li>
              <b>For advertising :- </b>Subject to applicable laws and
              regulations, we may use your personal information to inform our
              advertising and marketing strategy and to tailor our messaging to
              your needs. Where required by law, we will ask for your consent at
              the time we collect your data to conduct such marketing. An
              opt-out mechanism will be provided to you in each communication to
              enable you to exercise your right to opt out of any direct
              marketing. We never sell your information. You may withdraw this
              consent/opt-out at any time without affecting the lawfulness of
              processing based on your prior consent.
            </li>
            <br />
            <br />
            <h4>4. Automated Decision-Making</h4>
            <p>
              Our operation of the our Service relies on automated analysis of
              personal information provided by you, alongside that received from
              AML service provider and compliance service provider, name
              screening agencies and fraud prevention agencies, For more
              information on the anti-fraud measures adopted by us, please see
              section 7 of this Privacy Policy.
            </p>
            <p>
              We may use criteria such as your identifying information (e.g.,
              your name, tax id number or date of birth) to validate your
              identity against public records on an automated basis or without
              human/manual intervention.
            </p>
            <p>
              You may also request that we provide information about our
              methodology and ask us to verify that the automated decision has
              been made correctly. We may reject the request, as permitted by
              applicable law, including when providing the information would
              result in a disclosure of a trade secret or would interfere with
              the prevention or detection of fraud or other crime. However,
              generally in these circumstances we will verify (or request the
              relevant third party to verify) that the source data are
              functioning as anticipated without error or bias.
            </p>
          </ul>
          <br />
          <h4>
            5. Transmission, Storage and Security of Your Personal Information
          </h4>
          <p>
            <b>Security over the internet:</b> No data transmission over the
            Internet or website can be guaranteed to be secure from intrusion.
            However, we maintain commercially reasonable physical, electronic
            and procedural safeguards to protect your personal information in
            accordance with data protection legislative requirements.
          </p>
          <p>
            All information you provide to us is stored on our or our vendors’
            secure servers and accessed and used subject to our security
            policies and standards. Where we have given you (or where you have
            chosen) a password that enables you to access certain parts of our
            websites, you are responsible for keeping this password confidential
            and for complying with any other security procedures that we notify
            you of. We ask you not to share a password with anyone.
          </p>
          <p>
            Your personal information may be accessed by staff or suppliers in,
            transferred to, and/or stored at, a destination outside the UAE in
            which data protection laws may be of a lower standard. Regardless of
            location or whether the person is an employee or contractor, we will
            impose the same data protection safeguards that we deploy herein
            UAE.
          </p>
          <p>
            We will retain your personal information for as long as is necessary
            for the processing purpose(s) for which they were collected and any
            other permitted linked purpose (for example certain transaction
            details and correspondence may be retained until the time limit for
            claims in respect of the transaction has expired or in order to
            comply with regulatory requirements regarding the retention of such
            data). So if information is used for two purposes, we will retain it
            until the purpose with the latest period expires; but we will stop
            using it for the purpose with a shorter period once that period
            expires.
          </p>

          <p>
            We restrict access to your personal information to those persons who
            need to use it for the relevant purpose(s). Our retention periods
            are based on business needs, and your information that is no longer
            needed is either irreversibly anonymized (and the anonymized
            information may be retained) or securely destroyed. By way of
            example:
          </p>
          <ul>
            <li>
              use to perform a contract: in relation to your personal
              information used to perform any contractual obligation to you, we
              may retain that personal information whilst the contract remains
              in force plus a further period (depending on jurisdiction and
              other factors) to deal with any queries or claims thereafter;
            </li>
            <li>
              copies of evidence obtained in relation to AML checks: in relation
              to your personal information obtained in relation to AML checks,
              we may retain that personal information whilst our client
              relationship remains in force plus a further 7 years to deal with
              any queries or claims thereafter; and
            </li>
            <li>
              where claims are contemplated: in relation to any information
              where we reasonably believe it will be necessary to defend or
              prosecute or make a claim against you, us or a third party, we may
              retain that information for as long as that claim could be
              pursued.
            </li>
          </ul>
          <br />
          <p>
            <b>Anonymity:</b> Virtual currencies may not be fully anonymous as a
            result of the public digital ledgers reflecting these currencies.
            Generally, anyone can view the balance and transaction history of
            any public wallet address. We, and others who are able to match your
            public wallet address to other information about you, and also may
            be able to identify you from a blockchain transaction. Furthermore,
            third parties may use data analytics to identify other information
            about you. Please note that such third parties have their own
            privacy policies and that we do not accept any responsibility or
            liability for their policies or processing of your personal
            information.
          </p>
          <br />
          <h4>6. Your Rights & Contacting Us</h4>
          <p>
            <b>Marketing:</b> You have the right to ask us not to process your
            personal information for marketing purposes. We will inform you if
            we intend to use your information for such purposes or if we intend
            to disclose your information to any third party for such purposes.
            You can exercise your right to prevent such processing by not
            checking certain boxes on our marketing consent form. You can also
            exercise the right at any time by contacting us as set out below.
          </p>
          <p>
            <b>Updating Information:</b> We will use reasonable endeavors to
            ensure that your personal information is accurate. In order to
            assist us with this, you should notify us of any changes to the
            personal information that you have provided to us by contacting us
            as set out below.
          </p>
          <p>
            You can also exercise the right to opt-out anytime from our
            marketing communications or update your personal information, please
            contact us at{' '}
            <a href="mailto: info@encryptus.io">info@encryptus.io</a>
          </p>
          <br />
          <h4>
            7. Processing for AML & Fraud Prevention and Detection Purposes
          </h4>
          <p>
            Before we provide our services to you, we undertake checks for the
            purposes of preventing fraud and money laundering and to verify your
            identity. These checks require us to process personal information
            about you.
          </p>
          <p>
            The personal information you have provided, we have collected from
            you, or we have received from third parties will be used to prevent
            fraud and money laundering and to verify your identity.
          </p>
          <p>
            In order to do so, we may provide information to, obtain information
            from, and verify information with AML, name screening and compliance
            agencies. We will continue to exchange information with such parties
            while you have a relationship with us.
          </p>
          <p>
            We, AML, name screening and compliance agencies may also enable law
            enforcement agencies to access and use your personal information to
            detect, investigate and prevent crime.
          </p>
          <p>
            AML, name screening and compliance agencies can hold your personal
            information for different periods of time, and if you are considered
            to pose a fraud or money laundering risk, your data can be held for
            up to six years.
          </p>
          <p>
            As part of the processing of your personal information, decisions
            may be made by automated means. This means we may automatically
            decide that you pose a fraud or money laundering risk if our
            processing reveals your behavior to be consistent with money
            laundering or known fraudulent conduct, or is inconsistent with your
            previous submissions, or you appear to have deliberately hidden your
            true identity. You have rights in relation to automated decision
            making as set out in section 4 of this policy.
          </p>
          <p>
            As a consequence of processing, if we, AML, name screening and
            compliance agencies, determine that you pose a fraud or money
            laundering risk, we may refuse to provide the Services or we may
            stop providing existing services to you.
          </p>

          <br />
          <h4>8. Cookies</h4>
          <p>We use cookies on the websites.</p>
          <br />
          <h4>9. Changes to our Privacy Policy and/or Cookies Policy</h4>
          <p>
            We may change the content of our Privacy Policy from time to time in
            the future. We therefore encourage you to review this policy from
            time to time to stay informed of how we are using personal
            information.
          </p>
          <br />
          <h4>10. Eligibility</h4>
          <p>
            We do not allow access this Website or use of our Service anyone who
            is underage at your jurisdiction of residence or in the UAE. If in a
            circumstance we learn that we have received any information form an
            underage person, we shall inform the underage that he/she cannot use
            the Website or the Service.
          </p>
          <br />
          <h4>11. Notice to European Union Residents (“GDPR Notice”)</h4>
          <p>
            Under the European Union’s General Data Protection Regulation
            (GDPR), we are providing this GDPR notice to EU residents.
          </p>
          <p>
            We have already described in Section 3 and Section 5 above as to how
            we use your Personal Information and how we disclose or share your
            information. You have the following rights in your Personal
            Information
          </p>
          <ul>
            <li>
              You have the right to information about your Personal Information
              processed by us as to (a) the processing purposes; (b) the
              categories of Personal Information being process; (c) the
              recipient or categories of recipient who Personal Information have
              been disclosed to; (d) the planned duration of Personal
              Information to be stored; (e) the deletion or restricting the
              processing of Personal Information or to object such processing;
              (f) the appeal to a supervisory authority; (g) any automated
              decision making in processing your Personal Information; (h) the
              origin of your Personal Information if such information is not
              collected from you; and (i) the designation of Personal
              Information in case of transfer of information.
            </li>
            <li>
              You have the right to rectify any inaccurate Personal Information.
            </li>
            <li>
              You have the right to request immediate erasure of Personal
              Information if (a) the Personal Information is no longer necessary
              to be retained; (b) you revoke your consent; (c) you object the
              process your Personal Information as there is not legitimate
              ground to process it; and (d) your Personal Information was
              processed unlawfully.
            </li>
            <li>
              You have the right to restrict any processing of your Personal
              Information if (a) you object the accuracy of Personal
              Information; (b) the processing is unlawful but instead of erasure
              you chose to restrict the processing; (c) we do not need your
              Personal Information but you may need it for other purposes (d)
              you object the processing pursuant to Article 21(1) of the GDPR.
            </li>
            <li>
              You have the right to receive your Personal Information that you
              provided to us in a structured and readable format.
            </li>
          </ul>
          <p>
            If you wish to exercise your rights to your Personal Information,
            update, delete or restrict any process of Personal Information or
            revoke your consent to processing the same, please contact us at
            <a href="mailto : info@encryptus.io">info@encryptus.io</a> If there
            is any infringement of GDPR in relation to your Personal
            Information, you may lodge a complaint with a supervisory authority
            at your residence jurisdiction of European Union.
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
