import './App.scss';
import { Switch, Route, Redirect } from 'react-router-dom';
import OldSession from './pages/OldSession';
import Header from './components/Header';
import ClientDashboard from './pages/client/Dashboard';
import Checkout from './pages/client/Checkout';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminDashboard from './pages/admin/Dashboard';
import AdminHeader from './components/AdminHeader';
import Users from './pages/admin/Users';
import BankAccounts from './pages/client/BankAccounts';
import NewAdmin from './pages/admin/NewAdmin';
import { useUser } from './context/user';
import AdminSettings from './pages/admin/Settings';
import BankApplications from './pages/admin/BankApplications';
import KycApplications from './pages/admin/KycApplications';
import Wallets from './pages/client/Wallets';
import Transactions from './pages/admin/Transactions';
import WithdrawTransactions from './pages/admin/WithdrawTransactions';
import Verifyid from './pages/client/Verifyid';
import VerifyContact from './pages/client/VerifyContact';
import VerifyEmail from './pages/client/VerifyEmail';
import UserSettings from './pages/client/UserSettings';
import Permissions from 'pages/admin/Permissions';
import ChangePassword from './pages/loweradmin/ChangePassword';
import { useAdmin } from 'context/admin';
import Fintransaction from 'pages/loweradmin/Fintransaction';
import User from 'pages/admin/User';
import OrderSubmitted from 'pages/client/OrderSubmitted';
import TermsCondition from 'pages/TermsCondition';
import SellCheckout from 'pages/client/SellCheckout';
import CookieConsent from 'components/common/CookieConsent';
import SellTransactions from 'components/SellTransaction';
import UserTransaction from 'components/UserTransaction';
import Reports from 'pages/admin/Reports';
import OrderTracker from './pages/client/orderTracker';
import Balance from 'pages/client/Balance';
import { FinalLandingPage } from 'pages/FinalLandingPage';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import { UserAgreementPage } from 'pages/UserAgreement';
import { useEffect } from 'react';
// @ts-ignore
import socketio from 'socket.io-client';
import { useNotification } from 'context/notification';
import { CookiePolicy } from 'pages/CookiePolicy';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import 'intro.js/introjs.css';
import LsellTransactions from 'pages/loweradmin/LsellTransactions';
import WithdrawTransactionLowerAdmin from 'pages/loweradmin/WithdrawTransactionLowerAdmin';
import Luser from 'pages/loweradmin/Lusers';
import LusersData from 'pages/loweradmin/LusersData';
import BuyTransactions from 'pages/admin/BuyTransaction';
import AdminLogin from 'pages/AdminLogin';
import Cryptobuy from 'pages/loweradmin/Cryptobuy';
import MobileTopups from 'pages/admin/MobileTopups';
import GiftCards from 'pages/admin/GiftCards';
import PartnerWithdrawTransactions from 'pages/admin/partners/PartnerWithdrawTransactions';
import PartnerDepositTransactions from 'pages/admin/partners/PartnerDepositTransactions';
import PGiftcards from 'pages/admin/partners/PGiftcards';

toast.configure({ theme: 'colored', position: 'top-center' });
export default function App() {
  const { user, token } = useUser();
  const { adminToken, admin } = useAdmin();
  const { handleNotifications } = useNotification();
  /**update socket-client */
  useEffect(() => {
    if (user?.email || admin?.email) {
      const sock: any = socketio.connect(`${process.env.REACT_APP_SERVER_HOST as string}`);
      console.log(sock);
      let email = '';
      if (admin) email = admin.email;
      else if (user) email = user.email;
      sock.emit('newUser', email);
      sock.on('notifications', (notifData: any) => {
        handleNotifications(notifData);
      });
    }
    // eslint-disable-next-line
  }, [user, admin]);
  return (
    <div className="App">
      <CookieConsent />
      <Switch>
        {/* Global Routes++++++++++++++++++ */}
        <Route path="/verifiedEmail" component={VerifyEmail} />
        <Route path="/oldSession" component={OldSession} />
        <Route path="/termsCondition" component={TermsCondition} />
        <Route path="/legal/privacy" component={PrivacyPolicy} />
        <Route path="/useragreement" component={UserAgreementPage} />
        <Route path="/cookiepolicy" component={CookiePolicy} />
        <Route path="/adminlogin" component={AdminLogin} />
        {/* end of global Routes+++++++++++++ */}
        <Route>
          {admin && adminToken && <AdminHeader />}
          {user && token && <Header />}
          <Switch>
            <Route exact path="/" component={FinalLandingPage} />
            <Route path="/admin">
              {admin && adminToken ? (
                <Switch>
                  {admin.role === 'admin' ? (
                    <Switch>
                      {/* Super Admin Routes++++++++++++++++ */}
                      <Route
                        path="/admin/dashboard"
                        exact
                        component={AdminDashboard}
                      />
                      <Route path="/admin/user/:id" component={User} />
                      <Route path="/admin/users" component={Users} />
                      <Route
                        path="/admin/transactions"
                        component={Transactions}
                      />
                      <Route
                        path="/admin/Buytransactions"
                        component={BuyTransactions}
                      />
                      <Route
                        path="/admin/withdraw_transactions"
                        component={WithdrawTransactions}
                      />
                      <Route
                        path="/admin/selltransactions"
                        component={SellTransactions}
                      />
                      <Route
                        path="/admin/permissions"
                        component={Permissions}
                      />
                      <Route
                        path="/admin/bank_applications"
                        component={BankApplications}
                      />
                      <Route path="/admin/gift_cards" component={GiftCards} />
                      <Route
                        path="/admin/mobile_topup"
                        component={MobileTopups}
                      />
                      <Route path="/admin/create" component={NewAdmin} />
                      <Route
                        path="/admin/kyc_applications"
                        component={KycApplications}
                      />
                      <Route path="/admin/reports" component={Reports} />
                      <Route path="/admin/settings" component={AdminSettings} />
                      <Route
                        path="/admin/partner_deposit_transactions"
                        component={PartnerDepositTransactions}
                      />
                      <Route
                        path="/admin/partner_withdraw_transactions"
                        component={PartnerWithdrawTransactions}
                      />
                      <Route
                        path="/admin/partner_giftcards"
                        component={PGiftcards}
                      />
                      <Route path="/admin">
                        <Redirect to="/admin/dashboard" />
                      </Route>
                      {/* End of Super Admin Routes++++++++++++ */}
                    </Switch>
                  ) : (
                    <Switch>
                      {/* Lower Admin Routes+++++++++++++ */}
                      <Route
                        path="/admin/dashboard"
                        component={AdminDashboard}
                      />
                      <Route
                        path="/admin/sellTransactions"
                        component={LsellTransactions}
                      />
                      <Route path="/admin/lusers" component={Luser} />
                      <Route
                        path="/admin/lusersData/:id"
                        component={LusersData}
                      />
                      <Route path="/admin/users" component={Users} />
                      <Route
                        path="/admin/financetransactions"
                        component={Fintransaction}
                      />
                      <Route
                        path="/admin/withdrawtransactions"
                        component={WithdrawTransactionLowerAdmin}
                      />
                      <Route path="/admin/settings" component={AdminSettings} />
                      <Route
                        path="/admin/bank_applications"
                        component={BankApplications}
                      />
                      <Route
                        path="/admin/change_password"
                        component={ChangePassword}
                      />
                      <Route path="/admin/ladmin_buy" component={Cryptobuy} />
                      <Route path="/admin">
                        <Redirect to="/admin/dashboard" />
                      </Route>
                    </Switch>
                  )}
                  <Route path="/admin">
                    <Redirect to="/admin/dashboard" />
                  </Route>
                </Switch>
              ) : (
                <Route>
                  <Redirect to="/" />
                </Route>
              )}
            </Route>
            <Route path="/">
              {
                /************* FOR DEV ************/
                token &&
                  user?.role === 'fintech' &&
                  (!user.isEmailVerified || !user.isPhoneVerified) ? (
                  <VerifyContact />
                ) : token && !user?.kycStatus && user?.role === 'fintech' ? (
                  <Verifyid />
                ) : //
                  /**********************************/
                  token && (!user || user?.role === 'fintech') ? (
                    <Switch>
                      <Route path="/dashboard" component={ClientDashboard} />
                      <Route path="/order" component={OrderTracker} />
                      <Route path="/checkout" component={Checkout} />
                      <Route path="/sellcheckout" component={SellCheckout} />
                      <Route
                        path="/orders/tracker/:id"
                        component={OrderTracker}
                      />
                      <Route path="/orders/:id" component={OrderSubmitted} />
                      <Route path="/wallets" component={Wallets} />
                      <Route path="/settings_user" component={UserSettings} />
                      <Route
                        path="/usertransactions"
                        component={UserTransaction}
                      />
                      <Route path="/bank_accounts" component={BankAccounts} />
                      <Route path="/balance" component={Balance} />
                    </Switch>
                  ) : (
                    <Route>
                      <Redirect to="/" />
                    </Route>
                  )
              }
            </Route>
          </Switch>
        </Route>
      </Switch>
    </div>
  );
}
