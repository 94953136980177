import { useEffect, useState } from 'react';
import NavBar from 'components/common/NavBar';
import { useUser } from 'context/user';
import { useHistory } from 'react-router-dom';
import two_one from '../assets/NewPhotos/bg2.png';
import three_one from '../assets/FinalLandingPage/compressed/container_three-min.png';
import three_two from '../assets/FinalLandingPage/compressed/three_two-min.png';
import four_one from '../assets/NewPhotos/bg1.png';
import ten_one from '../assets/FinalLandingPage/compressed/ten_one-min.png';
import eleven_one from '../assets/FinalLandingPage/compressed/eleven_one-min.png';
import arrow_down from '../assets/arrow-down.svg';
import '../styles/FinalLandingPage.scss';
import Footer from 'components/common/Footer';
import { toast } from 'react-toastify';
import { ROUTES } from 'config';
import axios from 'axios';
// eslint-disable-next-line
const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const FinalLandingPage = () => {
  const { user } = useUser();
  const history = useHistory();

  const [accordianState, setAccordianState] = useState<number>(0);

  // ? ENQUIRY FORM VALUES
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [enquiry, setEnquiry] = useState<string>('');
  const [referrer, setReferrer] = useState<string>('');
  // eslint-disable-next-line
  const [error, setError] = useState<string>('');
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1023);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1023);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  const updateAccordianState = (faq: number) => {
    if (accordianState === faq) setAccordianState(0);
    else setAccordianState(faq);
  };

  useEffect(() => {
    if (!user) return;
    if (user?.role === 'admin') history.push('/admin');
    else history.push('/dashboard');
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    checkLocation();
  }, []);

  const checkLocation = async () => {
    try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(ROUTES.CHECK_LOCATION);
      if (data) {
        return;
      }
    } catch (error: any) {
      if (error?.response?.status === 403) {
        toast.error(
          `Thank you for visiting Encryptus. Regrettably, you are accessing Encryptus from a country, 
          including the USA and Canada, where our services are not provided. We kindly ask you to review our User 
          Agreement for additional details. If you are neither in a restricted country nor a national or resident 
          of one, please reach out to our customer service team at hello@encryptus.io.`,
        );
      }
    }
  };

  const handleFormSubmit = async (event: any) => {
    event.preventDefault();

    if (name === '' || email === '' || enquiry === '' || referrer === '') {
      setError('* Please fill all the details!');

      return;
    }

    if (!emailRegex.test(email)) {
      setError('* Please enter a valid email address!');

      return;
    }

    const payload: Object = {
      name,
      email,
      enquiry,
      referrer,
    };

    try {
      const { data }: any = await axios.post(ROUTES.SUBMIT_ENQUIRY, payload);

      if (data?.status && data?.status === 'success') {
        toast.success(data?.message);
      } else {
        toast.warn(
          data?.message || 'Error submitting enquiry, please try again!',
        );
      }
    } catch (error: any) {
      toast.warn('Error submitting enquiry, please try again!');
    }
  };

  // useEffect(()=)

  return (
    <>
      <NavBar />
      <section>
        {/* Container 1 */}
        <div className="container md:h-[70vh]">
          <div className="bg-image bg-center absolute w-full h-[80vh] z-0 md:mt-48 md:h-[55vh]"></div>
          <div className="mx-20 md:mx-3 z-10 TextDiv">
            <h1 className="text-4xl md:text-2xl md:text-center leading-[4rem] md:leading-normal break-normal">
              LICENSED AND TRANSPARENT APPROACH <br />{' '}
              <span style={{ color: '#DBB42C' }}>TO CRYPTO ASSETS</span>{' '}
            </h1>
            <h4 className="text-xl md:text-sm md:text-center">
              Encryptus makes Cryptos &lt; &gt; Fiat conversion <br />
              simple and compliant.
            </h4>
          </div>
        </div>
        {/* Container 2 */}
        <div className="container_two h-[90vh] flex-row-reverse pt-24 md:pt-40 md:block">
          <h2 className="mr-10 text-4xl md:text-xl md:text-center leading-[4rem] md:leading-normal break-normal lg:text-center">
            SERVING DIVERSIFIED <br /> INSTITUTIONS
            <h4 className="text-2xl md:text-sm md:text-center break-normal">
              <br />
              No matter which industry our clients are from, they can either use
              our services on our website or they can duplicate our ecosystem in
              their business.{' '}
              <b>We are here to support and build a sharing economy.</b>
            </h4>{' '}
          </h2>
          <img
            src={two_one}
            alt="institutional crypto exchange"
            className="h-full two_one_image md:mt-10 md:h-[50vh] md:w-[50vw] md:object-cover md:mr-10"
          />
        </div>
        {/* Container 3 */}
        <div className="container_three">
          {isDesktop ? (
            <img src={three_one} alt="crypto to fiat" />
          ) : (
            <img
              src={three_two}
              alt="Supporting Currency"
              className="w-full h-screen"
            />
          )}
        </div>
        {/* Container 4 */}
        <div className="container_four py-10 md:pt-20 lg:block">
          <h2 className="mt-30 md:mt-8 mx-16 md:mx-8 text-4xl md:text-xl md:text-center leading-[6rem] md:leading-normal break-normal">
            LICENSED AND COMPLIANT
            <h4 className="text-2xl md:text-sm md:text-center break-normal">
              We take compliance very seriously and therefore{' '}
              <b>
                we are integrated with Industry leaders for Coin Monitoring, KYC
                Checks and wallet custody solutions.
              </b>
            </h4>
            <h4 className="text-2xl md:text-sm md:text-center break-normal">
              Everything on our platform is compliant and monitored to keep the
              ecosystem clean.<b> We are licensed for crypto activities</b> in
              UAE by DMCC, authorized by Lithuania and seeking to expand our
              operational bandwidth.
            </h4>
          </h2>
          <img
            src={four_one}
            alt="crypto trading platform encryptus"
            className="h-[90vh] xl:w-[60vw] lg:w-screen md:mt-10 md:h-[60vh] md:w-screen md:object-cover"
          />
        </div>
        {/* Container 5 */}
        <div className="container_five flex-row-reverse lg:mt-[-2rem] lg:relative lg:block z-10">
          <h2
            className="w-[35vw] text-end lg:w-screen py-25 md:pt-10 mr-10 text-3xl md:text-xl lg:text-center leading-[3rem] md:leading-normal break-normal"
            style={{ padding: '25px 35px' }}
          >
            SIMPLIFIED AND AUTOMATED
            <h4 className="text-xl md:text-sm md:text-center leading-[2rem] break-normal font-thin">
              <br /> We have truly automated important areas like{' '}
              <b className="font-black">
                Client Onboarding, Bank Wires, Wallets Whitelisting and many
                other areas{' '}
              </b>
              , by <b> adopting the Best technology &amp; Common Sense</b>.{' '}
              <br /> <br />
              <b className="font-black">
                {' '}
                Users can also consume our entire infrastructure by simply
                plugging into our APIs{' '}
              </b>
              . We provide a simple, secure and compliant ecosystem to buy and
              sell cryptocurrencies.
            </h4>
          </h2>
          <div className="ImgDiv"></div>
        </div>
        {/* Container 6 */}
        <div className="container_six h-[100vh] md:h-[95vh] md:pt-0 lg:block">
          <h2 className="w-[40vw] md:w-[90vw]  mx-16 md:mx-4 text-4xl md:text-xl md:text-center leading-[3rem] md:leading-normal break-normal">
            POWERHOUSE FOR INSTITUTIONAL LARGE ORDERS
            <h4 className="text-2xl md:text-sm md:text-center break-normal">
              <br />
              <b>
                Encryptus is seamlessly connected with the large traders and
                supported by LBank Exchange for Liquidity
              </b>
              . We are well funded and use our funds at banks and LPs to execute
              the large order both on the buy and sell. <br /> <br /> We support
              all major crypto assets including: Bitcoin, Ethereum, USDC and on
              fiat we support GBP, EUR and USD. Our bank accounts are in the{' '}
              <b>UK, US and Europe.</b>
            </h4>
          </h2>
          {/* image div++++++++++++++ */}
          <div className="ImgDiv"></div>
        </div>
        {/* Container 7 */}
        <div className="container_seven h-[90vh] md:h-screen md:pt-0 flex-row-reverse relative lg:block">
          <h2 className="w-[35vw] text-end lg:w-[90vw] pt-20 md:pt-10 mr-20 md:mr-0 text-3xl md:text-xl leading-[4rem] md:leading-normal break-normal">
            STEPPING UP API’S
            <h4 className="md:w-[75vw] md:ml-16 text-xl md:text-sm leading-[3rem] break-normal font-thin">
              <br />
              <b>
                Enabling large crypto and non-crypto companies to launch their
                own desk{' '}
              </b>
              . Clients will be able to connect to our APIs soon to offer
              compliant and <b>transparent trading services </b> to their
              customers.
            </h4>
          </h2>
          {/* <img
            src={seven_one}
            alt="backgroundImage"
            className="h-[90vh] md:h-[80vh] w-screen md:object-cover md:mr-10"
          /> */}
          {/* image div++++++++++++++ */}
          <div className="ImgDiv"></div>
        </div>

        {/* Container 8 */}
        <div className="relative container_eight h-[90vh] md:w-screen md:h-[65vw] md:object-contain">
          <h2 className="text-4xl md:w-screen md:text-base pt-10 md:p-4 text-center">
            FOUNDATION
          </h2>
        </div>
        {/* Container 9 */}
        {/* <div className="container_nine relative h-[80vh] md:w-screen">
          <h1 className="text-4xl md:w-screen md:text-xl py-5 text-center">
            Partners
          </h1>
        </div> */}
        {/* <div className="container_nine"></div> */}
        {/* Container 10 */}
        <div className="container_ten py-10 md:pt-10 lg:block relative">
          <h2 className="md:mt-0 mx-16 md:mx-3 text-4xl md:text-xl md:text-center leading-[6rem] md:leading-normal break-normal">
            Our Core Values
            <br />
            <h4 className="text-2xl md:text-sm md:text-justify break-normal text_two">
              Encryptus launched as a crypto advisory company in London in 2020
              with an aim to provide transparent and impartial advisory.
              <br /> <br />
              Encryptus’s foundation has always been compliance and therefore we
              went ahead to acquire our first crypto licence for “Proprietary
              Trading in Crypto Commodities” from DMCC (Dubai Multi Commodities
              Centre) in Aug 2021.
              <br /> <br />
              In August 2022, Encryptus got authorized by the Lithuanian
              authorities to operate as a "Cryptocurrency exchange and Wallet
              Operator" in the region.
              <br /> <br />
              Encryptus operates on the four pillars of expertise, simplicity,
              compliance, and ecosystem sharing. Our mission is to empower
              licensed financial services companies in crypto adoption.
              Encryptus is an institutional-grade platform which not only
              provides a licensed means to buy and sell crypto assets, but also
              allows companies and institutions globally. to enter into the
              crypto space by consuming their ecosystem.
            </h4>
          </h2>

          <img
            src={ten_one}
            className="h-[90vh] xl:w-[60vw] lg:w-screen md:mt-10 md:h-[60vh] md:w-screen md:object-cover"
            alt="encryptus crypto to fiat"
          />
        </div>
        {/* Container 11 */}
        <div className="container_eleven justify-between flex-row lg:mt-[-5rem] lg:relative lg:block z-10">
          <img
            src={eleven_one}
            alt="crypto to bank transfer"
            className="h-full w-auto lg:h-screen lg:w-screen md:mt-10 md:h-[50vh] md:object-cover md:mr-10"
          />
          <h1 className="w-[40vw] text-end lg:w-screen pt-20 md:pt-10 mr-20 text-3xl md:text-xl lg:text-center leading-[4rem] md:leading-normal break-normal">
            FOUNDERS NOTE
            <h4 className="text-xl md:text-sm md:mx-3 md:text-justify leading-[3rem] break-normal font-thin">
              {/* <br /> */}
              After being in the crypto space for over 7 years I felt that there
              are some major gaps when it comes to crypto adoption for
              institutions. It is hard for institutions to adopt crypto because
              of a lack of understanding, unjustified rates and lack of
              compliance offered from crypto companies.
              <br /> <br /> Encryptus is not a token project and our aim is to
              establish a licensed approach for crypto assets using compliances,
              technology, expertise and sharing economy to bridge the gap
              between traditional finance and modern finance. We will continue
              to innovate Fiat &lt; &gt; Crypto bridges for institutions in a
              regulated manner. Encryptus will continue to be the backend
              company to support Institutions adopting crypto assets.
            </h4>
          </h1>
        </div>
        {/* Container 12 */}
        <div className="container_twelve relative pb-10">
          <div className="faq_screen">
            <h2 className="w-screen pt-10 md:pt-10 text-center text-4xl md:text-xl leading-[2rem] md:leading-normal break-normal">
              FAQs
            </h2>
          </div>
          <div className="questions w-[80vw] md:w-[90vw] md:pt-0 md:min-h-full flex flex-col mx-auto justify-center">
            <div className={`que ${accordianState === 1 ? 'open' : ''}`}>
              <h3
                className="flex justify-between items-center px-4 py-1 md:py-2 text-2xl md:text-sm leading-[3rem] md:leading-normal break-normal"
                onClick={() => updateAccordianState(1)}
              >
                What is Encryptus Desk?
                <img className="p-1 md:p-2" src={arrow_down} alt="arrow" />
              </h3>
              {accordianState === 1 && (
                <h4 className="md:text-sm text-justify">
                  When trading digital assets in large volumes, trading on
                  exchange will be complex and you will not know the execution
                  price beforehand. On the contrary, when trading with the
                  Encryptus Desk, you always trade with a given quotation which
                  will later be the execution price and you have a dedicated
                  support team to trade securely and smoothly. You are also
                  likely to get a better price when trading with the Encryptus
                  Desk in large size trades.
                </h4>
              )}
            </div>
            <div className={`que ${accordianState === 2 ? 'open' : ''}`}>
              <h3
                className="flex justify-between items-center px-4 py-1 md:py-2 text-2xl md:text-sm leading-[3rem] md:leading-normal break-normal"
                onClick={() => updateAccordianState(2)}
              >
                Is Encryptus Desk Licensed?
                <img className="p-1 md:p-2" src={arrow_down} alt="arrow" />
              </h3>

              {accordianState === 2 && (
                <h4 className="md:text-sm text-justify">
                  Yes, Encryptus Desk is Licensed by DMCC (Dubai Multi
                  Commodities Centre)
                </h4>
              )}
            </div>
            <div className={`que ${accordianState === 3 ? 'open' : ''}`}>
              <h3
                className="flex justify-between items-center px-4 py-1 md:py-2 text-2xl md:text-sm leading-[3rem] md:leading-normal break-normal"
                onClick={() => updateAccordianState(3)}
              >
                Does Encryptus custody crypto assets ?
                <img className="p-1 md:p-2" src={arrow_down} alt="arrow" />
              </h3>

              {accordianState === 3 && (
                <h4 className="md:text-sm text-justify">
                  No, Encryptus doesn’t custody customers crypto assets
                </h4>
              )}
            </div>
            <div className={`que ${accordianState === 4 ? 'open' : ''}`}>
              <h3
                className="flex justify-between items-center px-4 py-1 md:py-2 text-2xl md:text-sm leading-[3rem] md:leading-normal break-normal"
                onClick={() => updateAccordianState(4)}
              >
                Which crypto currencies are available with Encryptus?{' '}
                <img className="p-1 md:p-2" src={arrow_down} alt="arrow2" />
              </h3>
              {accordianState === 4 && (
                <h4 className="md:text-sm text-justify">
                  Bitcoin (BTC), Ethereum (ETH) and USDC
                </h4>
              )}
            </div>
            <div className={`que ${accordianState === 5 ? 'open' : ''}`}>
              <h3
                className="flex justify-between items-center px-4 py-1 md:py-2 text-2xl md:text-sm leading-[3rem] md:leading-normal break-normal"
                onClick={() => updateAccordianState(5)}
              >
                Does Encryptus needs customers KYC?{' '}
                <img className="p-1 md:p-2" src={arrow_down} alt="arrow3" />
              </h3>
              {accordianState === 5 && (
                <h4 className="md:text-sm text-justify">
                  Even if a customer is buying or selling crypto assets worth
                  USD1, they need to provide the KYC document. Not just do we do
                  EDD on the customer but also run coin monitoring tools to make
                  sure we are dealing with clean crypto assets and wallets
                </h4>
              )}
            </div>
            <div className={`que ${accordianState === 6 ? 'open' : ''}`}>
              <h3
                className="flex justify-between items-center px-4 py-1 md:py-2 text-2xl md:text-sm leading-[3rem] md:leading-normal break-normal"
                onClick={() => updateAccordianState(6)}
              >
                Can a customer be denied services?{' '}
                <img className="p-1 md:p-2" src={arrow_down} alt="arrow" />
              </h3>

              {accordianState === 6 && (
                <h4 className="md:text-sm text-justify">
                  Yes, if the customer fails the KYC, we reserve the rights to
                  deny Encryptus services
                </h4>
              )}
            </div>
            <div className={`que ${accordianState === 7 ? 'open' : ''}`}>
              <h3
                className="flex justify-between items-center px-4 py-1 md:py-2 text-2xl md:text-sm leading-[3rem] md:leading-normal break-normal"
                onClick={() => updateAccordianState(7)}
              >
                Why choose Encryptus desk?
                <img className="p-1 md:p-2" src={arrow_down} alt="arrow" />
              </h3>
              {accordianState === 7 && (
                <h4 className="md:text-sm text-justify">
                  The Encryptus Desk offers:
                  <br />
                  <br />
                  ∗ Experienced Market Insights
                  <br />
                  ∗ A 1-on-1 service
                  <br />
                  ∗ 360 support
                  <br />
                  ∗ Global Coverage
                  <br />
                </h4>
              )}
            </div>
            <div className={`que ${accordianState === 8 ? 'open' : ''}`}>
              <h3
                className="flex justify-between items-center px-4 py-1 md:py-2 text-2xl md:text-sm leading-[3rem] md:leading-normal break-normal"
                onClick={() => updateAccordianState(8)}
              >
                Why should I use Encryptus desk to trade?
                <img className="p-1 md:p-2" src={arrow_down} alt="arrow" />
              </h3>
              {accordianState === 8 && (
                <h4 className="md:text-sm text-justify">
                  Anyone who is after a compliant way of buying or selling their
                  crypto assets against fiat.
                </h4>
              )}
            </div>
          </div>
        </div>
        <div className="container_thirteen" id="contact">
          <h2>ENQUIRY FORM</h2>
          <form onSubmit={handleFormSubmit}>
            <div className="input-row">
              <label>Name</label>

              <input
                type="text"
                onChange={(event) => setName(() => event.target.value)}
                required
              />
            </div>

            <div className="input-row">
              <label>Email</label>

              <input
                type="email"
                onChange={(event) => setEmail(() => event.target.value)}
                required
              />
            </div>

            <div className="input-row">
              <label>Where did you hear about us?</label>

              {/* <br /> */}

              <input
                type="text"
                onChange={(event) => setEnquiry(() => event.target.value)}
                required
              />
            </div>
            <div className="input-row">
              <label>Enquiry</label>

              <textarea
                onChange={(event) => setReferrer(() => event.target.value)}
                rows={6}
                // cols={4}
                required
              />
            </div>

            <div className="submit_class_div w-screen flex justify-center pt-10">
              <button
                className="submit_class w-[10vw] xl:w-[20vw] md:w-[50vw] py-2"
                type="submit"
              >
                SUBMIT
              </button>
            </div>
          </form>
        </div>
        <Footer />
      </section>
    </>
  );
};
