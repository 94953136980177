import Footer from 'components/common/Footer';
import NavBar from 'components/common/NavBar';
import UserAgreementTwo from '../assets/UserAgreementTwo.png';
import '../styles/CookiePolicy.scss';
import React, { useEffect } from 'react';

export const CookiePolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavBar />
      <div className="main_containter_c">
        <div className="container_c">
          <div className="inner_div">
            <h1>
              Cookie <span style={{ color: '#fff' }}> Policy </span>
            </h1>
          </div>
        </div>
        <div className="container_first_c">
          <div className="first_para_c">
            Dated: 30th May 2022
            <br />
            <br />
            This Cookie Policy explains how Encryptus, Inc. and its group
            companies and affiliated companies ("Encryptus", "we", "us" and
            "our") use cookies and similar technologies when you visit our
            websites located at www.encryptus.io (the “Website”) or any other
            websites or mobile apps we own or operate. This Policy explains what
            these technologies are and why we use them, as well as your rights
            to control our use of them. <br /> <br /> In some cases, we may use
            cookies and similar technologies to collect personal information, or
            information that becomes personal information if we combine it with
            other information. In such cases, our Privacy Policy shall also
            apply.
          </div>
          <img src={UserAgreementTwo} alt="FooterImage" />
          <br />
        </div>
        <div className="container_second_c">
          <h1>What Are Cookies</h1>
          <p>
            <br />
            When you access our website, we may make use of the standard
            practice of placing tiny data files called cookies, flash cookies,
            pixel tags, or other tracking tools (namely “Cookies”) on your
            computer or other devices used to visit our website. We use Cookies
            to help us recognize you as a client or user, collect data about
            your use of our website to better customize our facilities, services
            and other subjects and content for you, and to collect data about
            your computer or other access devices as part to ensure our
            compliance with AML or other obligations and ensure that your
            account security has not been compromised by detecting irregular or
            suspicious account activities. Please note that the information
            collected by cookies is anonymous aggregated research data, and
            contain no name or address information or any information that will
            enable anyone to contact you via telephone, email or any other
            means. We may also work with third parties to research certain usage
            and activities on parts of our web site on our behalf. Most browsers
            are initially set to accept cookies. You have the option to decline
            the use of our Cookies, but this may affect the functionality of our
            facilities, services and other subjects or your user experience.
            <br /> <br />
            Cookies is a common practice used by most professional or commercial
            websites for the purpose of improving your experience on the
            Website. These technologies help us better understand user
            behaviour, and inform us about which parts of our websites people
            have visited. <br /> <br /> This policy describes what information
            we collect, how we use it and why we need to store these cookies. We
            will also share how you can prevent these cookies from being stored,
            of which this may affect or ‘break’ certain features of the sites
            and may affect your user experience on this Website.
            <br /> <br /> <br />
          </p>

          <h1>How We Use Cookies</h1>

          <p>
            <br />
            We may place cookies from third-party service providers who may use
            information about your visits to other websites to target
            advertisements for products and services available from Encryptus.
            We may not control the types of information collected and stored by
            these third-party cookies. <br /> <br /> We use cookies for a
            variety of reasons detailed below. <br /> <br /> We may collect and
            store details of how you use our Website. Except in limited
            instances to ensure quality of our services over the internet, such
            information will not be associated with your IP address. We may
            collect information such as your language, inferred zip code or area
            code, unique device identifier, referrer URL, location, and time
            zone so that we can better understand your behaviour and improve our
            services We may collect information regarding your activities on our
            websites and platforms, which is used to understand which parts of
            our Website are of most interest.
            <br /> <br /> <br />
          </p>

          <h1>Disabling Cookies</h1>

          <p>
            <br />
            You can prevent the setting of cookies by adjusting the settings on
            your browser (see your browser Help for how to do this). Be aware
            that disabling cookies will affect the functionality of this and
            many other websites that you visit. Disabling cookies will usually
            result in also disabling certain functionality and features of this
            Website. Therefore it is recommended that you do not disable cookies
            if you wish to have full function of this Website. Encryptus do not
            bear any liabilities in any event if you have disabled cookies on
            this Website which affect any of our service on the Website.
            <br /> <br /> <br />
          </p>

          <h1>The Cookies We Set </h1>
          <p>
            <br />
            Account related cookies. If you create an account with us then we
            will use cookies for the signup process and general administration.
            These cookies will usually be deleted when you log out. However in
            some cases they may remain afterwards to remember your site
            preferences when logged out. <br /> Forms related cookies. When you
            submit data to through a form such as those found on contact pages
            or comment forms cookies may be set to remember your user details
            for future correspondence.
            <br /> <br />
            If you have any questions on this Policy you may contact us at
            &ensp;{' '}
            <span style={{ color: '#DBB42C' }}>
              <a href="mailto: compliance@encryptus.io">
                compliance@encryptus.io
              </a>
            </span>
            <br /> <br /> <br />
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};
