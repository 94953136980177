import Footer from 'components/common/Footer';
import NavBar from 'components/common/NavBar';
import UserAgreementTwo from '../assets/UserAgreementTwo.png';
import '../styles/UserAgreement.scss';
import React, { useEffect } from 'react';

export const UserAgreementPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavBar />
      <div className="main_containter">
        <div className="container">
          <div className="inner_div">
            <h1>
              User <span style={{ color: '#fff' }}> Agreement </span>
            </h1>
          </div>
        </div>
        <div className="container_first">
          <p className="first_para">
            Encryptus DMCC is a company incorporated under the laws of United
            Arab Emirates,and UAB Encryptus Europe is a company incorporated
            under the laws of Lithuania (Encryptus DMCC  and UAB Encryptus
            Europe collectively referred to as, “Encryptus”, “the Company”,
            “ourselves”, “we” or “us” ). We offer you the use of the Encryptus
            website &#40;the “Site” or “Website”&#41;, subject to this
            Agreement, the Know-Your-Customer &#40;KYC&#41; and Anti-Money
            Laundering &#40;AML&#41; policy &#40; “KYC/AML Policy” &#41; and the
            privacy policy &#40; “Privacy Policy” &#41;. &#40;The KYC/AML
            Policy, Privacy Policy, and any additional agreement that you agree
            to, are referred together as the “Agreement”&#41;
          </p>
          <p className="second_para">
            PLEASE READ THE AGREEMENT CAREFULLY BEFORE USING OUR SITE AND REVIEW
            THEM PERIODICALLY AND REGULARLY FOR CHANGES. BY VISITING THE
            WEBSITE, REGISTERING FOR OR USING OR ANY PART THEREOF, YOU AGREE TO
            BE BOUND BY THE AGREEMENT AND AGREE THAT SUCH AGREEMENT CONSTITUTE A
            LEGALLY BINDING AGREEMENT BETWEEN YOU AND US. IF YOU DO NOT AGREE TO
            BE FULLY BOUND BY ALL OR ANY OF THE AGREEMENT, YOU SHOULD EXIT THE
            SITE IMMEDIATELY AND NOT TO MAKE ANY USE OF THE WEBSITE OR OUR
            SITES.
            <br />
            PLEASE NOTE THAT WE RESERVE THE RIGHT, AT OUR SOLE DISCRETION, TO
            REVISE THE AGREEMENT, AT ANY TIME WITHOUT NOTICE.
          </p>
        </div>
        <img src={UserAgreementTwo} alt="FooterImage" />
        <div className="container_second">
          <ol className="ordered_list">
            <li className="definations">1. DEFINITIONS</li>
            <p>
              <br />
              In the Agreement, the following terms shall have the meaning set
              forth beside them: <br /> <br /> “The Company”, “ourselves”, “we”
              or “us” means Encryptus and any or all of its affiliates.
              <br />
              <br />
              <b>“Restricted Jurisdictions”</b> includes the USA (including customer
              whose residence address is in the United States, holding US
              passport or green card, with nationality as United States or who was
              born in the United States), Afghanistan, Armenia, Belarus, Canada,
              Cuba, Iraq, Iran, Japan, Myanmar, Russia, North Korea, Syria, Ukraine,
              Venezuela or any jurisdictions as may be designated by Encryptus from
              time to time. <br />
              <br />
              “User”, “your” or “you” means the individual or entity using or
              accessing this Website. <br />
              <br />
              “Verified User” means a User who has submitted to Encryptus the
              required documents in order to complete the identity verification
              process.
            </p>
            <br /> <br />
            <li>2.THE WEBSITE, REGISTRATION AND ACCESS.</li>
            <p>
              <br />
              2.1 Encryptus may enable Users access to the following services
              through the Website:-<br></br>
              a. purchase and sale digital assets;<br></br>
              b. acquire information, knowledge and news in the digital asset
              industry;<br></br>
              c. providing wallet and/or custodian services;<br></br>
              d. distributing digital assets;<br></br>
              e. Any services related to digital assets’ transaction;<br></br>
              f. Any services related to investment or funds in digital assets;
              and/or<br></br>
              g. Any future services to be provided by Encryptus as announced or
              posted on the Website.<br></br>
              (Collectively the “Service”).<br></br>
              <p>
                (Collectively the "<b>Service</b>"")
              </p>
            </p>
            <p>
              <br />
              2.2 The Website is currently offered on a self-service basis and
              may charge fees for the use of Service on the website. For a full
              breakdown of the fees please go to our fees schedule. The Fees
              listed under fees schedule maybe revised by Encryptus from time to
              time and Encryptus has the sole discretion to revise the fees
              without notification to User.
            </p>
            <p>
              <br />
              2.3 In order to access and use our Service, you must register on
              the website as a User, complete the sign-up process, create an
              account (the “Account”) through our site and go throught the
              identity verification process. You are also required to provide
              your personal information so as to create the Account.
            </p>
            <p>
              <br />
              2.4 If and when you register as a User or otherwise use the
              website, you represent and warrant that: (i) you have reached the
              legal age and capacity to form a binding contract and have the
              full capacity to accept this Agreement and enter into transactions
              as a result of using the website; (ii) you have provided us with
              accurate, true and complete information about yourself (including,
              but not limited to, your name and email address); (iii) to the
              extent you represent one or more businesses or franchises, that
              you are acting as their authorized representative, and that both
              you and the entity you represent will be bound by the Agreement;
              (iv) your usage of the website is and will be in full compliance
              with all laws and regulations, applicable to your use of the
              webiste is not prohibited by any applicable laws or regulations,
              and is not for illegal or immoral purpose; (v) your registration
              and your use of the website will be consistent with the foregoing
              license, covenants and restrictions, and you are not infringing
              nor violating the rights of any other party; and (vi) you agree to
              abide by any relevant laws or regulations in your jurisdiction,
              including but not limited to reporting any trading activities or
              profits for taxation purposes.
            </p>
            <p>
              <br />
              2.5 We reserve the right to suspend or terminate the Account,
              according to our sole discretion and without providing any prior
              notice, your registration or access to the website, in the event
              that (i) when we reasonably suspect that you have breached any of
              the Agreement, (ii) when we reasonably suspect fraudulent or
              illegal activities, or (iii) when the Agreement has been
              terminated.
            </p>
            <p>
              <br />
              2.6 You are solely and wholly responsible for the security of your
              Account and we urge you to keep your account password secure and
              to always log-off when leaving the website.
            </p>
            <p>
              <br />
              2.7 You acknowledge and agree that you are solely and wholly
              responsible for obtaining and maintaining any hardware,
              facilities, connections, licenses, permits, database, equipment,
              external software or any other resources as may be required and/or
              necessary for the use of the website (such as, computer, mobile
              device, internet connection and telecommunications) and any
              relevant payment system. You agree that we will not bear any
              liability for any cost, expense, loss or other damage you may
              suffer directly or indirectly with respect to such hardware,
              facilities, connections, licenses, permits, database, equipment or
              external software or any other resources as may be required, in
              connection with use of the website.
            </p>
            <p>
              <br />
              2.8 You agree to defend and indemnify the Company and any of their
              officers, directors, employees and agents from and against any
              claims, causes of action, demands, recoveries, losses, damages,
              fines, penalties or other costs or expenses of any kind or nature
              including but not limited to reasonable legal and accounting fees,
              brought by third parties as a result of (1) your breach of this
              agreement or the documents referenced herein; (2) your violation
              of any law or the rights of a third party; or (3) your use of the
              Account and/or this website.
            </p>
            <br />
            <li>3.OWNERSHIP.</li>
            <p>
              <br />
              3.1 The Website, including any enhancements, derivatives and
              improvements thereof, are the sole property of Encryptus. Without
              limitation of the foregoing, all the text, images, sounds, music,
              marks, logos, compilations (meaning the collection, arrangement
              and assembly of information) and other content on the Website
              (collectively, the “Content”), and all software embodied in the
              website or otherwise used by Encryptus to deliver the website’s
              function (“Software”), are the property of Encryptus and are
              protected by copyright and other intellectual property laws.
              Nothing in the Agreement shall grant the User any of such property
              and/or ownership.
            </p>
            <p>
              <br />
              3.2 Any rights with respect to the Software or the Content which
              are not expressly granted here by Encryptus are reserved by
              Encryptus.
            </p>
            <br />
            <li>4.INTELLECTUAL PROPERTY LICENSE, RESTRICTION.</li>
            <p>
              <br />
              4.1 We grant you a personal, revocable, non-assignable,
              non-perpetual and non-exclusive right to access and use the
              website as a licensee, provided that you fully comply with the
              Agreement.
            </p>
            <p>
              <br />
              4.2 You shall not remove any copyright, trademark or other
              proprietary notices that have been placed on the website.
            </p>
            <p>
              <br />
              4.3 You shall not exploit the website in any unauthorized way
              whatsoever, including but not limited to trespass or materially
              burden network capacity and you shall not use the website for any
              illegal or immoral purpose, or in violation of any applicable laws
              and regulations, such as laws governing intellectual property and
              other proprietary rights, data protection and privacy, anti-money
              laundering and currency control. You acknowledge that you are
              fully responsible for satisfying any legal and regulatory
              requirements applicable in countries and territories in which you
              operate.
            </p>
            <p>
              <br />
              4.4 We reserve the right to apply limits on the use of the
              website, for example, by limiting the available features, or
              number of supported Users and / or transactions. We further
              reserve the right to publish such limitations, make them different
              from User to User, or change such limitations at will, in each
              case in our sole discretion.
            </p>
            <br />
            <li>5.USER VERIFICATION, COMPLIANCE AND RESTRICTION.</li>
            <p>
              <br />
              5.1 Only Verified Users can purchase and sale of digital assets on
              the Website.. You hereby authorize us, directly or through our
              third-party service providers, to take any measures that we
              consider necessary to verify your identity, confirm the
              information you submit about your linked bank account, and to take
              any action we deem necessary based on the results.
            </p>
            <p>
              <br />
              5.2 By visiting our Website, you hereby authorize us, directly or
              through our third-party service providers, to seek information
              from your network service provider, to obtain your IP address and
              to obtain the location of your IP address Persons or located in
              the countries specified as Restricted Jurisdictions are not
              permitted to use the Website. This restriction also applies to
              residents and citizens of other nations while located in a
              Restricted Jurisdictions. The fact that the Website is accessible
              in a Restricted Jurisdictions, or that the Website allows the use
              of the official language of a Restricted Jurisdictions, shall not
              be construed as a license to use the Website in such Restricted
              Jurisdictions. Any attempt to circumvent this restriction, for
              example, by using a virtual private network, proxy or similar
              service that masks or manipulates the identification of your real
              location, or by otherwise providing false or misleading
              information regarding your citizenship, location or place of
              residence, or by using the website through a third party or on
              behalf of a third party located in a Restricted Jurisdictions is a
              breach of this Agreement. If it becomes apparent, or we have
              reasonable grounds to suspect, that you are located in any of the
              Restricted Jurisdictions, this may result in closing your Account,
              without an obligation to provide you with advance notice, all in
              accordance with applicable laws and agreements.
            </p>
            <br />
            <li>6.FEES, TAXES AND PAYMENT TERMS.</li>
            <p>
              <br />
              6.1 The website provided to you is subject to fees in the fees
              schedule on the Website.{' '}
            </p>
            <p>
              <br />
              6.2 You agree that you are responsible to calculate, validate and
              pay any and all sales, use, excise, import, export, value added,
              withholding and other taxes and duties assessed, incurred, or
              required to be collected ( “Taxes”). Encryptus and its affiliates
              are not responsible for determining whether Taxes apply to your
              transaction, or for collecting, reporting or remitting any taxes
              arising from any transaction or for reporting any information, to
              you or any taxation, governing or third authority. You agree to
              indemnify us and our affiliates against any claim or demand for
              payment of any fines, penalties, similar charges or costs imposed
              or incurred as a result of your failure to remit or report any
              Taxes in connection with any transaction.
            </p>
            <p>
              <br />
              6.3 Should such fees apply, without derogating from any other
              remedy available to Encryptus, Encryptus at its sole discretion,
              may suspend or terminate the access and right to use all or any
              portion of the website, at any time, upon electronic notice to
              you, if you fail to pay the fees or any part there of one time.
            </p>
            <p>
              <br />
              6.4 Encryptus has the sole discretion to amend any fees listed on
              the fees schedule. While Encryptus strives to provide up to date
              statements on any fees changes, Encryptus does not guarantee all
              the changes will be received by you by any means. You are advise
              to review the fees schedule from time to time. You shall stop
              using the website if you disagree with the fees.
            </p>
            <br />
            <li>7.DATA COLLECTION.</li>
            <p>
              <br />
              7.1 You agree that we may periodically collect, store and use data
              (including, but not limited to technical information) in
              connection with your registration or use of the Site and other
              Website ( “Usage Data”). We may also collect information about
              Users who access through the website (“User Data”). In addition,
              our systems may automatically collect other data about your
              operations, depending on the specific technology used by you for
              the implementation of the website.
            </p>
            <p>
              <br />
              7.2 The Usage Data and User Data is our property. We may use such
              data: to provide our service to you and to others; to improve the
              website; to personalize and enhance the use of the website; to
              determine those areas which are useful or popular and those that
              are less; to keep record of correspondence with you; to address
              any issues that you are having with the website; to evaluate your
              use, preferences and trends for internal statistical and
              analytical purposes and in respect of operations and product
              development. We may also use the Usage Data and User Data to offer
              cross-promotions of Encryptus and its Service.
            </p>
            <p>
              <br />
              7.3 We may, at our sole discretion, provide you with information (
              “Confidential Information”). Any such information shall remain the
              property of Encryptus. The User shall hold the Confidential
              Information in strict confidence. Without Encryptus prior written
              consent, such Confidential Information shall not be disclosed by
              the User in whole or in part to any person other than those agents
              and employees of the User who need to know about the Confidential
              Information for the sole purposes of using our website. The User
              further agrees that Encryptus’s Confidential Information, whether
              in written or electronic form, shall not be copied or reproduced
              at any time without Encryptus’s prior written consent. In
              addition, in case the User receives and uses the Confidential
              Information, the User shall abide by our privacy policy as if you
              were Encryptus in such privacy policy.
            </p>
            <p>
              <br />
              7.4 Encryptus may share your personal and other information
              (“Information”) with our affiliate companies or third party to
              verify and validate information, and to address customer service
              matters. By using the Website and/or registering the Account, you
              hereby agree to your Information will be passed to our affiliate
              companies or third party solely for the purpose to facilitate your
              usage of the Website or the Account. While Encryptus does not sell
              your information, we may need to share or pass your information
              with third parties acting on our behalf or as may be required by
              law, regulations or court order. We will use reasonable efforts to
              ensure that your Information is used in a reasonable manner and
              you hereby agree and permit Encryptus to share your Information
              with regulators, law enforcement authorities and government
              agencies if Encryptus is so requested.
            </p>
            <p>
              <br />
              7.5 Encryptus will store your Information in accordance with the
              data protection principles contained under the laws of United Arab
              Emirates. All of our policies related to your Information shall be
              contained in the Private Policy.
            </p>
            <p>
              <br />
              7.6 Our KYC/AML Policy is guided by the Financial Action Task
              Force guidance, specifically, the AML and Counter-Terrorist
              Financing (“CTF”) provisions. These standards are designed to
              prevent the use of the website or the service to aid money
              laundering or terrorist financing activities. We take compliance
              very seriously and it is our policy to take all necessary steps to
              prohibit fraudulent transactions, report suspicious activities,
              and actively engage in the prevention of money laundering and
              related acts that facilitate money laundering, terrorist financing
              or any other financial crimes.
            </p>
            <p>
              Our KYC/AML Policy also focuses on the detection and prevention of
              fraudulent activity in the following ways:
              <ul>
                <li>
                  Identification and assessment of fraud-related risk areas;
                </li>
                <li>
                  Procedures and controls to protect against identified risks;
                </li>
                <li>Allocation of responsibility for monitoring risks; and</li>
                <li>
                  Procedures for the periodic evaluation of revision of the
                  anti-fraud procedures, controls and monitoring mechanisms
                </li>
              </ul>
            </p>
            <br />
            <li>
              8.PROHIBITED USE, PROHIBITED BUSINESSES AND CONDITIONAL USE.
            </li>
            <p>
              <br />
              8.1 You may not use your Account to engage in the following
              categories of activity ("Prohibited Uses"). The specific types of
              use listed below are representative, but not exhaustive. By
              opening an Account, you confirm that you will not use your Account
              to do any of the following:
              <br /> <br />
              <span style={{ fontWeight: 'bold' }}>
                Unlawful Activity:
              </span>{' '}
              Activity which would violate, or assist in violation of, any law,
              statute, ordinance, or regulation, sanctions programs administered
              in any countries, including but not limited to the persons or
              countries listed by the United Arab Emirates Financial
              Intelligence Unit (FIU), or which would involve proceeds of any
              unlawful activity; publish, distribute or disseminate any unlawful
              material or information; <br /> <br />{' '}
              <span style={{ fontWeight: 'bold' }}> Abusive Activity: </span>{' '}
              Actions which impose an unreasonable or disproportionately large
              load on our infrastructure, or detrimentally interfere with,
              intercept, or expropriate any system, data, or information;
              transmit or upload any material to the Site that contains viruses,
              Trojan horses, worms, or any other harmful or deleterious
              programs; attempt to gain unauthorized access to the Site, other
              Accounts, computer systems or networks connected to the Site,
              through password mining or any other means; use Account
              information of another party to access or use the Site, except in
              the case of specific merchants and/or applications which are
              specifically authorized by a user to access such user's Account
              and information; or transfer your Account access or rights to your
              Account to a third party, unless by operation of law or with the
              express permission of Encryptus;
              <br /> <br />{' '}
              <span style={{ fontWeight: 'bold' }}>
                {' '}
                Abuse Other Users:{' '}
              </span>{' '}
              Interfere with another individual's or entity's access to or use
              of any the Services defame, abuse, extort, harass, stalk, threaten
              or otherwise violate or infringe the legal rights (such as, but
              not limited to, rights of privacy, publicity and intellectual
              property) of others; incite, threaten, facilitate, promote, or
              encourage violent acts against others; harvest or otherwise
              collect information from the Site about others, including without
              limitation email address, without proper consent; <br /> <br />{' '}
              <span style={{ fontWeight: 'bold' }}> Fraud: </span> Activity
              which operates to defraud Encryptus or any other person; provide
              any false, inaccurate, or misleading information to Encryptus;
              Gambling: Lotteries; bidding fee auctions; sports forecasting or
              odds making; fantasy sports leagues with cash prizes; internet
              gaming; contests; sweepstakes; games of chance; and Intellectual
              Property Infringement: Engage in transactions involving items that
              infringe or violate any copyright, trademark, right of publicity
              or privacy or any other proprietary right under the law, including
              but not limited to sales, distribution, or access to counterfeit
              music, movies, software, or other licensed materials without the
              appropriate authorization from the rights holder; use of Encryptus
              intellectual property, name, or logo, including use of Encryptus
              trade or service marks, without express consent from Encryptus or
              in a manner that otherwise harms Encryptus or the Encryptus brand;
              any action that implies an untrue endorsement by or affiliation
              with Encryptus.
              <br /> <br />{' '}
              <span style={{ fontWeight: 'bold' }}>
                {' '}
                Intellectual Property Infringement: Engage in transactions
                involving items that infringe or violate any copyright,
                trademark, right of publicity or privacy or any other
                proprietary right under the law, including but not limited to
                sales, distribution, or access to counterfeit music, movies,
                software, or other licensed materials without the appropriate
                authorization from the rights holder; use of Encryptus
                intellectual property, name, or logo, including use of Encryptus
                trade or service marks, without express consent from Encryptus
                or in a manner that otherwise harms Encryptus or the Encryptus
                brand; any action that implies an untrue endorsement by or
                affiliation with Encryptus.
              </span>
              <br />
            </p>
            <br />
            <li>9.WARRANTIES AND DISCLAIMER.</li>
            <p>
              <br />
              You acknowledge, that to the maximum extent permitted by
              applicable law, the website and other materials provided to you by
              encryptus, are provided on an “as is” basis. Other than expressly
              provided under the agreement, encryptus gives no other warranties,
              express, implied, statutory or in any communication with user.{' '}
              <br /> The use of the website and reliance on the content availble
              is done solely at your own risk. Encryptus disclaims any and all
              warranties relating to the site, their content and other
              materials, express or implied, including, but not limited to the
              implied warranties of non-infringement of third party rights,
              satisfactory quality, availability, security, title, ability to
              operate and fitness for a particular purpose. <br /> <br /> Any
              reliance on the website is at the user’s own risk. Encryptus does
              not warrant that the website will meet your requirements, and does
              not guarantee that the use or other exploitation of the site will
              lead to certain results. No advice or information, whether oral or
              written, obtained by you from encryptus or through the website
              will constitute any warranty and/or representation. <br /> <br />{' '}
              We do not warrant nor represent that any content or the website
              provided will be accurate or error-free, that access thereto will
              be uninterrupted, that all defects will be corrected, or that the
              servers, and the content available there through is or will be
              free of viruses or other harmful components. <br /> <br />{' '}
              Encryptus may pause or interrupt the website at any time, and
              users should expect periodic downtime for updates to the website.{' '}
              <br /> <br /> We will not be liable to you or any third party for
              any direct, indirect, incidental, special, consequential or
              exemplary damages (including damages for loss of profits,
              goodwill, use, or data), even if a party has been advised of the
              possibility of such damages. Further, we will not be responsible
              for any compensation, reimbursement, or damages arising in
              connection with your inability to use the website, including as a
              result of any (i) termination or suspension of the agreement or
              your use of or access to the website, (ii) our discontinuation of
              any or all of the website, or, (iii) without limiting any
              obligations under any separately agreed service-level agreements,
              any unanticipated or unscheduled downtime of all or a portion of
              the website for any reason, including as a result of power
              outages, system failures or other interruptions; (b) the cost of
              procurement of substitute goods or website; (c) any investments,
              expenditures, or commitments by you in connection with this
              agreement or your use of or access to the website; or (d) any
              unauthorized access to, alteration of, or the deletion,
              destruction, damage, loss or failure to store any of your content
              or other data.
            </p>
            <br />
            <li>10.LIABILITIES, INDEMNITIES &amp; DISCLAIMER</li>
            <p>
              <br />
              10.1 You shall, upon our demand, defend, indemnify, and hold
              harmless us, and each of our respective employees, officers,
              directors, and representatives from and against any claims,
              damages, losses, liabilities, costs, expenses (including but not
              limited to debt collection expenses) and legal fees (including
              reasonable attorneys’ fees) arising out of or relating to any
              third party claim. If we are obligated to respond to a third-party
              subpoena or other compulsory legal order or process described
              above, you shall also reimburse us for legal costs, as well as our
              employees’ and contractors’ time and materials spent responding to
              the third-party subpoena or other compulsory legal order or
              process at our then-current hourly rates.
            </p>
            <p>
              <br />
              10.2 You shall indemnify us on demand for any damages and
              additional expense (including but not limited to legal costs and
              debt collection expenses) Encryptus may suffer or incur, directly
              or indirectly, as a result of unlawful actions conducted by you.{' '}
            </p>
            <p>
              <br />
              10.3 The information, software, products, and services published
              on this website may include inaccuracies or typographical errors.
              In particular, Encryptus does not guarantee the accuracy of and
              disclaim liability for inaccuracies relating to any information or
              descriptions displayed on this website.{' '}
            </p>
            <p>
              <br />
              10.4 Encryptus makes no representations about the suitability of
              the information, software, and services contained on this website
              for any purpose, and displaying or promotion of any digital assets
              on this website does not constitute any endorsement or
              recommendation of investment. Nothing on the Website constitute
              any investment advice or any inducement of investment.
            </p>
            <p>
              <br />
              10.5 In no event shall Encryptus be liable for any direct,
              indirect, punitive, incidental, special, or consequential damages
              arising out of, or in any way connected with, the use of this
              website with the delay or inability to use this website, or for
              any services obtained through this website, or otherwise arising
              out of the use of this website, whether based on contract, tort,
              strict liability, or otherwise, even Encryptus, its affiliates,
              and/or their respective suppliers have been advised of the
              possibility of damages.
            </p>
            <p>
              <br />
              10.6 You agree to defend and indemnify Encryptus and any of their
              officers, directors, employees and agents from and against any
              claims, causes of action, demands, recoveries, losses, damages,
              fines, penalties or other costs or expenses of any kind or nature
              including but not limited to reasonable legal and accounting fees,
              brought by:
              <ul>
                <li>
                  you or on your behalf in excess of the liability described
                  above; or
                </li>
                <li>
                  by third parties as a result of (1) your breach of this
                  agreement or the documents referenced herein; (2) your
                  violation of any law or the rights of a third party; or (3)
                  your use of this website
                </li>
              </ul>
            </p>
            <br />
            <li>11.TERM, TERMINATION AND SURVIVAL.</li>
            <p>
              <br />
              11.1 The Agreement shall, in addition to any other terms and
              conditions, be as agreed between Encryptus and the User, and in
              absence of any other indications the Agreement shall commence upon
              Encryptus’s acceptance of the User’s registration to the Website,
              and shall remain valid until further written notice is given by
              Encryptus.
            </p>
            <p>
              <br />
              11.2 Without prejudice to other provisions (especially clause 11.1
              above) in the Agreement, Encryptus may terminate the Website, the
              Agreement and all rights granted hereunder immediately and without
              notice.
            </p>
            <br />
            <li>12.INACTIVE ACCOUNTS.</li>
            <p>
              <br />
              12.1 Encryptus reserves the right to close inactive accounts that
              have exhibited no events or transaction activity for a period
              greater than 3 years. The Users whose Accounts will be closed as a
              result of this condition will be contacted by Encryptus using your
              registered email address fourteen (14) days prior to Account
              closure. After the Account is closed, you will have thirty (30)
              days to make arrangement for any funds held in the account or
              notify Encryptus by email that you wish to keep the Account open.{' '}
            </p>
            <br />
            <li>13.THIRD PARTY WEBSITES.</li>
            <p>
              <br />
              13.1 Encryptus may link to other third-party websites for
              convenience only and are not part of the Site. Encryptus does not
              endorse, confirm or support the contents of third-party websites.
              Encryptus is not responsible for the content of a third-party
              website and cannot attest to the accuracy and completeness of such
              content. You acknowledge that your use of any third-party website
              is at your own risk.
            </p>
            <br />
            <li>14.CYBERATTACKS AND MODIFICATIONS ON BLOCKCHAIN NETWORK.</li>
            <p>
              <br />
              14.1 Encryptus does not have any ability to prevent or mitigate
              cyberattacks and modifications on the blockchain networks
              (“Modification”), and Encryptus does not guarantee any insurance
              will be cover any cyberattack. Encryptus reserves the right to
              take commercially reasonable actions in such event.{' '}
            </p>
            <br />
            <li>15.DIGITAL ASSET RISK</li>
            <p>
              <br />
              15.1 Purchase and sale of digital assets involves significant
              risk. The risk of loss can be substantial. You should therefore
              carefully consider whether such purchase and sale is suitable for
              you in light of your risk exposure.
            </p>
            <p>
              <br />
              15.2 You shall bear any loss as a result of his/her own actions,
              including but not limited to:
              <ul>
                <li>
                  “Fat finger” input or instructions errors, including price or
                  quantity errors;
                </li>
                <li>Mis-timing or mis-submission of instructions;</li>
                <li>Forgetting or leaking your password;</li>
                <li>
                  Computer or network issues, including any hacks or virus
                  related issues;
                </li>
                <li>
                  Transfer-in or withdrawal of digital assets or fiat currencies
                  to or from the wrong account;
                </li>
                <li>
                  Third parties accessing and using your account for any reason.
                </li>
              </ul>
            </p>
            <br />
            <li>16.MISCELLANEOUS.</li>
            <p>
              <br />
              16.1 Encryptus and the User are and intend to remain independent
              parties. Nothing in the Agreement shall be deemed or construed to
              create the relationship of principal and agent or of partnership
              or joint venture between Encryptus and the User.
            </p>
            <p>
              <br />
              16.2 The Agreement represents the entire agreement between
              Encryptus and User and supersedes any and all other agreements
              between the parties, whether written or oral, regarding the
              subject matter hereof (but not include any add-on services as may
              be requested by the User). Unless otherwise specified in writing
              by Encryptus, The Agreement shall govern and supersede any other
              agreement entered into between Encryptus and you. Encryptus may
              assign its rights or obligations pursuant to the Agreement while
              the User agrees not to assign any rights under the Agreement
              without Encryptus’s explicit written consent. Any translation of
              the Agreement is provided for your convenience only and in case of
              any conflict, the English version shall prevail.
            </p>
            <p>
              <br />
              16.3 No waiver of any term of the Agreement shall be deemed a
              further or continuing waiver of such term or any other term, and
              any failure to assert any right or provision under the Agreement
              shall not constitute a waiver of such term.
            </p>
            <p>
              <br />
              16.4 The section titles in the Agreement are solely used for the
              convenience and have no legal or contractual significance.
            </p>
            <p>
              <br />
              16.5 Your electronic assent or use of the services shall
              constitute execution of the Agreement. You agree that the
              electronic text of the Agreement constitutes a “writing” and your
              assent to the terms and conditions hereof constitutes a “signing”
              for all purposes.
            </p>
            <p>
              <br />
              16.6 If any part of the Agreement shall be invalid or
              unenforceable, such invalidity or unenforceability shall not
              affect the validity or enforceability of any other part or
              provision of the Agreement which shall remain in full force and
              effect. If any provision or part-provision of the Agreement is or
              becomes invalid, illegal or unenforceable, it shall be deemed
              modified to the minimum extent necessary to make it valid, legal
              and enforceable. If such modification is not possible, the
              relevant provision or part-provision shall be deemed deleted. Any
              modification to or deletion of a provision or part-provision under
              this paragraph shall not affect the validity and enforceability of
              the rest of the Agreement.
            </p>
            <p>
              <br />
              16.7 Encryptus reserves the right, at its sole discretion, to
              change, add or remove portions of these Agreement, at any time.
              Such notification will be made via the Website and it is your
              responsibility to review the amended Agreement. Your continued use
              of the Website following the posting of changes will mean that you
              accept and agree to the changes and you agree that all subsequent
              transactions by you will be subject to these Agreement.
            </p>
            <p>
              <br />
              16.8 The Agreement shall be governed by the laws of the United
              Kingdom Special Administrative Region as to all matters including
              validity, construction, effect, performance and remedies without
              giving effect to the principles of choice of law thereof.{' '}
            </p>
            <p>
              <br />
              16.9 All investment decisions are made solely by you.
              Notwithstanding anything in this agreement, we accept no
              responsibility whatsoever for and shall in no circumstances be
              liable to you in connection with such decisions. Under no
              circumstances will the operation of Encryptus be deemed to create
              a relationship that includes the provision or tendering of
              investment advice.
            </p>
          </ol>
        </div>
      </div>
      <br />
      <Footer />
    </>
  );
};
